import { useState } from "react";

import {
  // @ts-expect-error unused
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ActionIcon,
  Button,
  Checkbox,
  Divider,
  Fieldset,
  Group,
  Stack,
  // @ts-expect-error unused
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Switch,
  // @ts-expect-error unused
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import ClearableTextInput from "./ClearableTextInput";
import LocationSelect from "./LocationSelect";
import SortPicker, { SortDirection } from "./SortPicker";

// @ts-expect-error unused
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IconBackspace } from "@tabler/icons-react";
import { Id } from "../../convex/_generated/dataModel";

export type PlantSortField = "name" | "location" | "ts";
export type PlantSortType = "numeric" | "alphabetical" | "size";
export type PlantSortDirectionObject = {
  [key in PlantSortField]: "ascending" | "descending";
};
// eslint-disable-next-line react-refresh/only-export-components
export const plantSortLabels: { [key in PlantSortField]: string } = {
  name: "Name",
  location: "Location",
  ts: "Age",
};

export type PlantFilterSettings = {
  name: string;
  locationId?: Id<"locations">;
  includeGone: boolean;
  sortFields: PlantSortField[];
  sortDirections: { [key in PlantSortField]: SortDirection };
};

type PlantFilterDialogProps = {
  initialValues: PlantFilterSettings;
  resetValues: PlantFilterSettings;
  onConfirm?: (values: PlantFilterSettings) => void;
  onCancel?: () => void;
};

export default function PlantFilterDialog({
  initialValues,
  resetValues,
  onConfirm,
  onCancel,
}: PlantFilterDialogProps) {
  const form = useForm<PlantFilterSettings>({
    mode: "uncontrolled",
    initialValues: initialValues,
  });

  const [sortFields, setSortFields] = useState<PlantSortField[]>(
    initialValues.sortFields
  );

  const [sortDirections, setSortDirections] =
    useState<PlantSortDirectionObject>(initialValues.sortDirections);

  const _onConfirm = (values: PlantFilterSettings) => {
    if (onConfirm) onConfirm(values);
  };
  return (
    <form onSubmit={form.onSubmit(_onConfirm)}>
      <Fieldset variant="unstyled">
        <Stack gap="lg">
          <Stack gap="xs">
            <Divider mt={0} label="Filter by" />
            <Stack gap="xs">
              <ClearableTextInput
                size="sm"
                label="Name"
                {...form.getInputProps("name")}
                key={form.key("name")}
                data-autofocus
                data-1p-ignore
                // rightSection={
                //   form.getValues().name ? (
                //     <ActionIcon
                //       variant="transparent"
                //       color="gray.6"
                //       size="sm"
                //       onClick={() => {
                //         form.setFieldValue("name", "");
                //       }}
                //     >
                //       <IconBackspace stroke="1.3" />
                //     </ActionIcon>
                //   ) : undefined
                // }
              />
              <LocationSelect
                size="sm"
                label="Location"
                {...form.getInputProps("locationId")}
                key={form.key("locationId")}
              />
              <Checkbox
                mt="xs"
                size="sm"
                label="Include archived plants"
                {...form.getInputProps("includeGone")}
                key={form.key("includeGone")}
              />
            </Stack>
            <Divider my="sm" label="Sort by" />
            <SortPicker
              fields={sortFields}
              setFields={(fields) => {
                form.setFieldValue("sortFields", fields);
                setSortFields(fields);
              }}
              directions={sortDirections}
              setDirections={(directions) => {
                form.setFieldValue("sortDirections", directions);
                setSortDirections(directions);
              }}
              labels={plantSortLabels}
            />
          </Stack>
          <Group justify="space-between" mt="md">
            <Button
              variant="light"
              color="red"
              onClick={() => {
                form.setValues(resetValues);
                setSortFields(resetValues.sortFields);
                setSortDirections(resetValues.sortDirections);
              }}
            >
              Reset
            </Button>
            <Group justify="flex-end">
              <Button variant="subtle" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Apply</Button>
            </Group>
          </Group>
        </Stack>
      </Fieldset>
    </form>
  );
}
