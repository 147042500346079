import { Box, Center, Modal, RingProgress } from "@mantine/core";
import { useDisclosure, useOs, useUncontrolled } from "@mantine/hooks";
import {
  IconCamera,
  IconLibraryPhoto,
  IconPhotoPlus,
  IconTrash,
} from "@tabler/icons-react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { CoverPhoto, StoredFile } from "../../convex/util";

import FileUploader, { FileUploaderHandle } from "./FileUploader";
import FloatingActionIcon from "./FloatingActionIcon";
import ImageButton from "./ImageButton";
import PhotoPicker from "./PhotoPicker";

type CoverPhotoInputProps = {
  value?: CoverPhoto;
  defaultValue?: CoverPhoto;
  onChange?: (value: CoverPhoto) => void;

  entryPhotos?: StoredFile[] | Promise<StoredFile[]>;
};

export type CoverPhotoInputHandle = {
  autoCapture: () => void;
};

const CoverPhotoInput = forwardRef<CoverPhotoInputHandle, CoverPhotoInputProps>(
  function (
    {
      value: _value,
      defaultValue,
      onChange,
      entryPhotos: _entryPhotos = [],
    }: CoverPhotoInputProps,
    ref
  ) {
    const [value, handleChange] = useUncontrolled<CoverPhoto>({
      value: _value,
      defaultValue,
      finalValue: null,
      onChange,
    });
    const [entryPhotos, setEntryPhotos] = useState<StoredFile[]>(
      _entryPhotos instanceof Promise ? [] : _entryPhotos
    );
    useEffect(() => {
      if (_entryPhotos instanceof Promise) {
        _entryPhotos.then((ep) => setEntryPhotos(ep));
      }
    }, [_entryPhotos]);

    const fileUploaderRef = useRef<FileUploaderHandle>(null);

    const os = useOs({ getValueInEffect: false });
    const isMobile =
      ["ios", "android"].includes(os) ||
      (os == `macos` && navigator.maxTouchPoints > 2);

    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [pickerOpened, { open: openPicker, close: closePicker }] =
      useDisclosure(false);

    const onCoverClick = entryPhotos.length
      ? openPicker
      : () =>
          isMobile
            ? fileUploaderRef.current?.openCamera()
            : fileUploaderRef.current?.showPicker();
    const CoverIcon = entryPhotos.length
      ? IconLibraryPhoto
      : isMobile
        ? IconCamera
        : IconPhotoPlus;
    const coverLabel = entryPhotos.length ? (
      <>Select cover photo&hellip;</>
    ) : isMobile ? (
      <>Take cover photo&hellip;</>
    ) : (
      <>Add cover photo&hellip;</>
    );

    useImperativeHandle(ref, () => ({
      autoCapture: () => {
        console.log("autoCapture");
        if (isMobile && entryPhotos.length == 0) {
          fileUploaderRef.current?.openCamera();
        }
      },
    }));

    return (
      <>
        <FileUploader
          ref={fileUploaderRef}
          onUploadReady={async (startUpload, file) => {
            handleChange({ file: file, source: "upload" });
            closePicker();
            setUploadProgress(0);
            setUploadInProgress(true);
            const storedFile = await startUpload();
            handleChange(
              storedFile ? { file: storedFile, source: "upload" } : null
            );
            setUploadInProgress(false);
          }}
          onUploadProgress={(p) => setUploadProgress(p)}
        />
        <Modal
          title="Select cover photo"
          opened={pickerOpened}
          onClose={closePicker}
          zIndex={300}
        >
          <PhotoPicker
            cols={3}
            photos={entryPhotos}
            onPhotoClick={(pickedPhoto) => {
              handleChange({ file: pickedPhoto, source: "entry" });
              closePicker();
            }}
            onAddClick={() => {
              fileUploaderRef.current?.showPicker();
            }}
          />
        </Modal>
        <Box display="inline block" pos="relative">
          <ImageButton
            w="100%"
            src={value?.file.url}
            onClick={onCoverClick}
            leftSection={<CoverIcon size={36} stroke={1.5} />}
          >
            {coverLabel}
          </ImageButton>
          {value?.file.url && !uploadInProgress && (
            <FloatingActionIcon
              color="gray.0"
              position="bottom-right"
              onClick={() => handleChange(null)}
            >
              <IconTrash
                style={{
                  opacity: 0.9,
                  filter: "drop-shadow(0px 1px 2px rgb(0 0 0))",
                }}
              />
            </FloatingActionIcon>
          )}
          {value === null && isMobile && (
            <FloatingActionIcon
              size="lg"
              position="bottom-right"
              onClick={() => fileUploaderRef.current?.showPicker()}
            >
              <IconLibraryPhoto />
            </FloatingActionIcon>
          )}
          {uploadInProgress && (
            <Center pos="absolute" inset={0}>
              <RingProgress
                size={60}
                thickness={10}
                sections={[{ value: uploadProgress, color: "blue" }]}
              />
            </Center>
          )}
        </Box>
      </>
    );
  }
);

export default CoverPhotoInput;
