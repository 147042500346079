import { CloseButton, TextInput, TextInputProps } from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";

type ClearableTextInputProps = TextInputProps & {
  onChange?: (value: TextInputProps["value"]) => void;
  clearable?: boolean;
  onClear?: () => void;
};

export default function ClearableTextInput({
  value: value,
  defaultValue: defaultValue,
  onChange: onChange,
  clearable: clearable = true,
  onClear: onClear,
  ...props
}: ClearableTextInputProps) {
  const [_value, setValue] = useUncontrolled({
    value: value,
    defaultValue: defaultValue,
    finalValue: "",
    onChange: onChange,
  });

  const clearButton = clearable &&
    !props.disabled &&
    !props.readOnly &&
    _value && (
      <CloseButton
        size={props.size || "sm"}
        onClick={() => {
          setValue("");
          onClear?.();
        }}
      />
    );
  return (
    <TextInput
      {...props}
      value={_value}
      onChange={(event) => setValue(event.currentTarget.value)}
      rightSection={clearButton}
    />
  );
}
