import React from "react";

// https://stackoverflow.com/questions/73015696/whats-the-difference-between-reacts-forwardedref-and-refobject
// https://non-traditional.dev/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
export default function useForwardedRef<T>(ref: React.ForwardedRef<T>) {
  const innerRef = React.useRef<T>(null);

  React.useEffect(() => {
    if (!ref) return;
    if (typeof ref === "function") {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
}
