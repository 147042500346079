import { Button, Center, Stack } from "@mantine/core";

import { useAuthActions } from "@convex-dev/auth/react";

function Landing() {
  const { signIn } = useAuthActions();
  return (
    <Center pos="absolute" inset={0}>
      <Stack>
        <Button
          fullWidth
          onClick={() =>
            void signIn("google", { redirectTo: window.location.href })
          }
        >
          Sign in
        </Button>
      </Stack>
    </Center>
  );
}

export default Landing;
