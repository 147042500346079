import { useContext } from "react";

import { Select, SelectProps } from "@mantine/core";
import { useQuery } from "convex/react";

import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";

import GardenContext from "./GardenContext";

type LocationSelectProps = {
  disabledLocations?: Id<"locations">[];
  disableRecursively?: boolean;
} & SelectProps;

export default function LocationSelect({
  disabledLocations: disabledLocations = [],
  disableRecursively: disableRecursively = true,
  ...selectProps
}: LocationSelectProps) {
  const currentGarden = useContext(GardenContext);
  const locations = useQuery(
    api.gardens.listLocations,
    currentGarden ? { gardenId: currentGarden._id } : "skip"
  );

  const disabledSet = new Set(disabledLocations);
  if (disableRecursively) {
    let keepGoing = true;
    while (keepGoing) {
      keepGoing = false;
      locations?.forEach((loc) => {
        if (
          loc.parentLocationId &&
          disabledSet.has(loc.parentLocationId) &&
          !disabledSet.has(loc._id)
        ) {
          keepGoing = true;
          disabledSet.add(loc._id);
        }
      });
    }
  }

  const { disabled: disabled, ...remainingSelectProps } = selectProps;
  return (
    <Select
      comboboxProps={{
        shadow: "md",
        offset: 0,
        zIndex: 3000,
      }}
      clearable={true}
      allowDeselect={false}
      searchable={true}
      checkIconPosition="right"
      data={
        locations
          ? locations.map((doc) => {
              return {
                value: doc._id,
                label: String.fromCharCode(0x00a0)
                  .repeat(doc.depth * 4)
                  .concat(doc.name),
                disabled: disabledSet.has(doc._id),
              };
            })
          : []
      }
      disabled={disabled || !locations || locations.length == 0}
      {...remainingSelectProps}
    />
  );
}
