import { useContext } from "react";

import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";

import {
  Autocomplete,
  AutocompleteProps,
  ComboboxItem,
  OptionsFilter,
} from "@mantine/core";

import GardenContext from "./GardenContext";

type PlantNameAutocompleteProps = AutocompleteProps;

export default function PlantNameAutocomplete(
  props: PlantNameAutocompleteProps
) {
  const currentGarden = useContext(GardenContext);
  const plants = useQuery(
    api.gardens.listPlants,
    currentGarden ? { gardenId: currentGarden._id } : "skip"
  );
  const plantNames = Array.from(
    new Set(plants ? plants.map((doc) => doc.name) : [])
  );

  const optionsFilter: OptionsFilter = ({ options, search }) => {
    if (search.length <= 1) {
      return [];
    }

    const splittedSearch = search.toLowerCase().trim().split(" ");
    const filtered = (options as ComboboxItem[])
      .filter((option) => {
        const words = option.label.toLowerCase().trim().split(" ");
        return splittedSearch.every((searchWord) =>
          words.some((word) => word.includes(searchWord))
        );
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    if (filtered.length == 1 && search == filtered[0].label) {
      // no point in showing autocomplete if the full thing is already typed
      return [];
    }
    return filtered;
  };

  return (
    <Autocomplete
      comboboxProps={{ shadow: "sm" }}
      filter={optionsFilter}
      {...props}
      data={plantNames}
    />
  );
}
