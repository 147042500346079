import { ReactNode, useState } from "react";

import { Box, Button, Fieldset, Group, Stack, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";

type DialogFormProps<FV> = {
  children: ReactNode;
  form: UseFormReturnType<FV>;
  ready?: boolean; // should confirm button be enabled
  confirmLabel: string;
  onConfirm?: (values: FV) => Promise<void>;
  cancelLabel?: string;
  onCancel?: () => void;
  destroyLabel?: string;
  onDestroy?: () => Promise<void>;
  destroyConfirm?: {
    title: string;
    text: string;
    confirm: string;
    cancel: string;
  };
};

export default function DialogForm<FV>({
  children,
  form,
  ready = true,
  confirmLabel,
  onConfirm,
  cancelLabel,
  onCancel,
  destroyLabel,
  onDestroy,
  destroyConfirm,
}: DialogFormProps<FV>) {
  const [confirmInProgress, setConfirmInProgress] = useState(false);
  const _onConfirm = async (values: FV) => {
    setConfirmInProgress(true);
    if (onConfirm)
      // TODO handle confirm failure
      await onConfirm(values);
    setConfirmInProgress(false);
    form.reset();
    if (!onCancel) {
      modals.closeAll();
    }
  };

  const [destroyInProgress, setDestroyInProgress] = useState(false);
  const _onDestroy = async () => {
    setDestroyInProgress(true);
    if (onDestroy) await onDestroy();
    form.reset();
    setDestroyInProgress(false);
    onCancel ? onCancel() : modals.closeAll();
  };

  const _onCancel = () => {
    form.reset();
    onCancel ? onCancel() : modals.closeAll();
  };

  const operationInProgress = confirmInProgress || destroyInProgress;

  return (
    <form onSubmit={form.onSubmit(_onConfirm)}>
      <Fieldset variant="unstyled" disabled={operationInProgress}>
        <Stack gap="lg">
          <Stack gap="sm">{children}</Stack>
          <Group justify="space-between">
            {onDestroy ? (
              <Button
                variant="light"
                color="red"
                disabled={operationInProgress}
                loading={destroyInProgress}
                onClick={() => {
                  modals.openConfirmModal({
                    id: "confirmDestroy",
                    title: destroyConfirm?.title,
                    children: <Text size="sm">{destroyConfirm?.text}</Text>,
                    labels: {
                      confirm: destroyConfirm?.confirm,
                      cancel: destroyConfirm?.cancel,
                    },
                    confirmProps: { color: "red" },
                    onCancel: () => {
                      modals.close("confirmDestroy");
                    },
                    onConfirm: async () => {
                      modals.close("confirmDestroy");
                      await _onDestroy();
                    },
                  });
                }}
              >
                {destroyLabel}
              </Button>
            ) : (
              <Box />
            )}
            <Group justify="flex-end">
              {cancelLabel ? (
                <Button
                  variant="subtle"
                  disabled={operationInProgress}
                  onClick={_onCancel}
                >
                  {cancelLabel}
                </Button>
              ) : (
                ""
              )}
              <Button
                type="submit"
                disabled={operationInProgress || !ready}
                loading={confirmInProgress}
              >
                {confirmLabel}
              </Button>
            </Group>
          </Group>
        </Stack>
      </Fieldset>
    </form>
  );
}
