import { useForm } from "@mantine/form";

import { Id } from "../../convex/_generated/dataModel";

import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

import { ConvexReactClient, useConvex, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { CoverPhoto, HydratedPlant, StoredFile } from "../../convex/util";

import { getCoverPhotoEntryDate } from "../lib/photo";

import CoverPhotoInput, { CoverPhotoInputHandle } from "./CoverPhotoInput";
import DialogForm from "./DialogForm";

import LocationSelect from "./LocationSelect";
import { openEditPlantLocationModal } from "./PlantLocationDialog";
import PlantNameAutocomplete from "./PlantNameAutocomplete";

type PlantFormValues = {
  name: string;
  coverPhoto: CoverPhoto;
  locationId: Id<"locations"> | null;
};

type PlantDialogProps = {
  gardenId: Id<"gardens">;
  plantId?: Id<"plants">;
  photoInput?: React.ForwardedRef<CoverPhotoInputHandle>;
  confirmLabel: string;
  onConfirm?: (values: PlantFormValues) => Promise<void>;
  cancelLabel?: string;
  onCancel?: () => void;
  destroyLabel?: string;
  onDestroy?: () => Promise<void>;
  destroyConfirm?: {
    title: string;
    text: string;
    confirm: string;
    cancel: string;
  };
  entryPhotos?: StoredFile[] | Promise<StoredFile[]>;
  initialValues?: PlantFormValues;
};

export default function PlantDialog({
  gardenId,
  plantId,
  photoInput,
  confirmLabel,
  onConfirm,
  cancelLabel,
  onCancel,
  destroyLabel,
  onDestroy,
  destroyConfirm,
  entryPhotos = [],
  initialValues = {
    name: "",
    coverPhoto: null,
    locationId: null,
  },
}: PlantDialogProps) {
  const convex = useConvex();
  const form = useForm<PlantFormValues>({
    mode: "uncontrolled",
    initialValues: initialValues,
    validate: {
      name: (value) => (value.length == 0 ? "A plant name is required" : null),
      coverPhoto: (value) =>
        value != null && value.file.storageId == null
          ? "Photo still uploading"
          : null,
    },
    // onValuesChange: (values) => {
    //   console.log("form onValuesChange", values);
    // },
  });

  const existingLocations = useQuery(
    api.gardens.listPlantLocations,
    plantId ? { gardenId, plantId } : "skip"
  );

  // console.log("PlantDialog render, form values:", form.getValues());

  return (
    <DialogForm
      form={form}
      confirmLabel={confirmLabel}
      onConfirm={onConfirm}
      cancelLabel={cancelLabel}
      onCancel={onCancel}
      destroyLabel={destroyLabel}
      onDestroy={onDestroy}
      destroyConfirm={destroyConfirm}
    >
      <CoverPhotoInput
        ref={photoInput}
        entryPhotos={entryPhotos}
        {...form.getInputProps("coverPhoto")}
        key={form.key("coverPhoto")}
      />
      <PlantNameAutocomplete
        // data-autofocus
        placeholder="Plant nam&#8203;e" // stupid hack to prevent Safari autofill
        {...form.getInputProps("name")}
        key={form.key("name")}
      />
      <LocationSelect
        label="Location:"
        clearable
        {...form.getInputProps("locationId")}
        key={form.key("locationId")}
      />
      {plantId && (
        <Button
          size="compact-xs"
          variant="white"
          justify="flex-start"
          style={{ marginLeft: -8, marginRight: -8, marginTop: -8 }}
          onClick={async () => {
            // probably cached by now, await just in case
            const existingLocations = await convex.query(
              api.gardens.listPlantLocations,
              {
                gardenId: gardenId,
                plantId: plantId,
              }
            );

            openEditPlantLocationModal(
              convex,
              gardenId,
              plantId,
              existingLocations,
              async (locations) => {
                // console.log("confirming new location history", locations);
                await convex.query(api.gardens.listPlantLocations, {
                  gardenId: gardenId,
                  plantId: plantId,
                });
                // setTimeout(() => {
                form.setFieldValue(
                  "locationId",
                  locations[locations.length - 1].locationId
                );
                //   console.log(
                //     "after setFieldValue, form values",
                //     form.getValues()
                //   );
                // }, 100);
              }
            );
          }}
        >
          {existingLocations && existingLocations.length > 1
            ? `And ${existingLocations.length - 1} previous ${existingLocations.length > 2 ? "locations" : "location"}\u2026`
            : "Location history\u2026"}
        </Button>
      )}
    </DialogForm>
  );
}

/* eslint-disable react-refresh/only-export-components */
export function openAddPlantModal(
  convex: ConvexReactClient,
  gardenId: Id<"gardens">
  // onSuccess?: (locationId: Id<"locations">) => void
) {
  modals.open({
    title: "Add plant",
    children: (
      <PlantDialog
        gardenId={gardenId}
        confirmLabel="Add"
        onConfirm={async (values) => {
          await convex.mutation(api.gardens.createPlant, {
            gardenId: gardenId,
            locationId: values.locationId,
            coverPhotoId: values.coverPhoto?.file.storageId || null,
            coverPhotoEntryDate: await getCoverPhotoEntryDate(
              values.coverPhoto
            ),
            name: values.name,
          });
          //  if (onSuccess) onSuccess(locationId);
        }}
        cancelLabel="Cancel"
      />
    ),
  });
}

/* eslint-disable react-refresh/only-export-components */
export function openEditPlantModal(
  convex: ConvexReactClient,
  doc: HydratedPlant
) {
  let entryQuery = convex.query(api.gardens.listPlantEntries, {
    gardenId: doc.gardenId,
    plantId: doc._id,
  });
  // workaround for Convex bug
  if (!(entryQuery instanceof Promise)) {
    entryQuery = Promise.resolve(entryQuery);
  }
  const entryPhotos = entryQuery.then((entries) =>
    entries.map((doc) => doc.photos).flat()
  );
  modals.open({
    title: "Edit plant",
    children: (
      <PlantDialog
        gardenId={doc.gardenId}
        plantId={doc._id}
        confirmLabel="Save"
        onConfirm={async ({ coverPhoto, ...newDoc }) => {
          await convex.mutation(api.gardens.updatePlant, {
            id: doc._id,
            fields: {
              ...newDoc,
              coverPhotoId: coverPhoto?.file.storageId || null,
            },
            coverPhotoEntryDate: await getCoverPhotoEntryDate(coverPhoto),
          });
        }}
        cancelLabel="Cancel"
        destroyLabel={doc.archived === false ? "Archive" : undefined}
        onDestroy={
          doc.archived
            ? undefined
            : async () => {
                await convex.mutation(api.gardens.archivePlant, {
                  id: doc._id,
                });
              }
        }
        destroyConfirm={{
          title: `Archive plant \u2018${doc.name}\u2019`,
          text: "Are you sure?",
          confirm: "Archive",
          cancel: "Cancel",
        }}
        initialValues={{
          name: doc.name,
          locationId: doc.location?.locationId || null,
          coverPhoto: doc.coverPhoto
            ? { file: doc.coverPhoto, source: "entry" }
            : null,
        }}
        entryPhotos={entryPhotos}
      />
    ),
  });
}
