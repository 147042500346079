import {
  ActionIcon,
  ActionIconProps,
  createPolymorphicComponent,
} from "@mantine/core";
import { forwardRef } from "react";

interface FloatingActionIconProps extends ActionIconProps {
  position: "top-left" | "top-right" | "bottom-left" | "bottom-right";
}

const FloatingActionIcon = createPolymorphicComponent<
  "button",
  FloatingActionIconProps
>(
  forwardRef<HTMLButtonElement, FloatingActionIconProps>(
    (
      {
        position,
        variant = "subtle",
        size = "lg",
        m = "xs",
        p = 5,
        style,
        ...props
      }: FloatingActionIconProps,
      ref
    ) => {
      const { top, right, bottom, left } = {
        top: position.startsWith("top") ? 0 : undefined,
        right: position.endsWith("right") ? 0 : undefined,
        bottom: position.startsWith("bottom") ? 0 : undefined,
        left: position.endsWith("left") ? 0 : undefined,
      };
      return (
        <ActionIcon
          ref={ref}
          pos="absolute"
          variant={variant}
          size={size}
          m={m}
          p={p}
          top={top}
          right={right}
          bottom={bottom}
          left={left}
          style={{
            borderRadius: "10000px",
            ...style,
          }}
          {...props}
        />
      );
    }
  )
);

export default FloatingActionIcon;
