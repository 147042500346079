// shared between server and client

export function toUTCDate(date: Date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
}

export function utcToday() {
  // returns a date in UTC timezone but where the year/month/day are corresponding to the local ymd
  const now = new Date();
  return toUTCDate(now);
}
