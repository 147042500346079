import React, { createContext, useContext, useRef } from "react";

import { Box, Transition } from "@mantine/core";
import { randomId } from "@mantine/hooks";
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useForwardedRef from "../lib/useForwardedRef";

export type NavPileHandle = {
  push: (node: ReactNode, title?: string) => void;
  pop: (n?: number) => void;
  popAll: () => void;
  getTitles: () => (string | undefined)[];
};

export const NavPileContext =
  createContext<React.RefObject<NavPileHandle> | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function usePile(
  enabled = true
): [
  (node: ReactNode, title?: string) => void,
  (n?: number) => void,
  () => void,
  (string | undefined)[],
] {
  const pile = useContext(NavPileContext)?.current;
  if (!enabled) return [() => {}, () => {}, () => {}, []];
  if (!pile) throw new Error();
  return [pile.push, pile.pop, pile.popAll, pile.getTitles()];
}

export type NavPileProps = { children: ReactNode; title?: string };

type PileItem = { node: ReactNode; id: string; title?: string };

const NavPile = forwardRef<NavPileHandle, NavPileProps>(
  (props: NavPileProps, forwardedRef) => {
    const ref = useForwardedRef(forwardedRef);
    return (
      <NavPileContext.Provider value={ref}>
        <NavPileInner ref={ref} {...props} />
      </NavPileContext.Provider>
    );
  }
);

export default NavPile;

const NavPileInner = forwardRef<NavPileHandle, NavPileProps>(
  ({ children: root, title: rootTitle }: NavPileProps, forwardedRef) => {
    // state and refs
    const [pile, _setPile] = useState<PileItem[]>(() => [
      { node: root, id: "root", title: rootTitle },
    ]);
    // also use a ref so that we can reference the variable in the handle functions
    const pileRef = useRef<PileItem[]>(pile);
    function setPile(newPile: PileItem[]) {
      pileRef.current = newPile;
      _setPile(newPile);
    }
    const [unmounting, _setUnmounting] = useState<PileItem[]>([]);
    const unmountingRef = useRef<PileItem[]>(pile);
    function setUnmounting(newPile: PileItem[]) {
      unmountingRef.current = newPile;
      _setUnmounting(newPile);
    }

    useImperativeHandle(forwardedRef, () => ({
      push: (node, title) => {
        const id = randomId();
        setPile([...pileRef.current, { node: node, id: id, title: title }]);
      },
      pop: (n: number = 1) => {
        if (pileRef.current.length <= n) return;
        setUnmounting([
          pileRef.current[pileRef.current.length - n],
          ...unmountingRef.current,
        ]);
        setPile(pileRef.current.slice(0, -n));
      },
      popAll: () => {
        if (pileRef.current.length <= 1) return;
        setUnmounting([...pileRef.current.slice(1), ...unmounting]);
        setPile(pileRef.current.slice(0, 1));
      },
      getTitles: () => pileRef.current.map((pi) => pi.title),
    }));

    return (
      <>
        {pile.map((item, index) => (
          <AutoTransitionBox
            direction="in"
            key={item.id}
            disableTransition={index === 0} // don't transition the root of the pile
          >
            {item.node}
          </AutoTransitionBox>
        ))}
        {unmounting.map((item) => (
          <AutoTransitionBox
            direction="out"
            key={item.id}
            onExited={() =>
              setUnmounting(unmounting.filter((_item) => _item.id != item.id))
            }
          >
            {item.node}
          </AutoTransitionBox>
        ))}
      </>
    );
  }
);

type AutoTransitionBoxProps = {
  children: ReactNode;
  direction: "in" | "out";
  onExited?: () => void;
  disableTransition?: boolean;
};

function AutoTransitionBox({
  children,
  direction,
  onExited,
  disableTransition = false,
}: AutoTransitionBoxProps) {
  const [mounted, setMounted] = useState(direction === "in" ? false : true);
  useEffect(() => {
    setMounted(direction === "in" ? true : false);
  }, [direction]);

  return (
    <Transition
      mounted={mounted}
      transition="slide-left"
      timingFunction="ease"
      onExited={onExited}
      duration={disableTransition ? 0 : undefined}
    >
      {(styles) => (
        <Box pos="absolute" inset={0} style={styles}>
          {children}
        </Box>
      )}
    </Transition>
  );
}
