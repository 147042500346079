import { Anchor, Box, Group, Text } from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import { usePile } from "./NavPile";

export default function PileBackLink() {
  const [, pop, , titles] = usePile();
  return (
    <Box ml={-2}>
      <Anchor underline="never" onClick={() => pop()}>
        <Group gap={0}>
          <IconChevronLeft size={16} />
          <Text size="sm">
            {titles.length > 1 ? titles[titles.length - 2] : <>&nbsp;</>}
          </Text>
        </Group>
      </Anchor>
    </Box>
  );
}
