import { ReactNode, useRef } from "react";

import { ScrollArea, ScrollAreaProps } from "@mantine/core";
import { useOs } from "@mantine/hooks";
import { IconArrowsVertical } from "@tabler/icons-react";

import FastScrollThumb, { FastScrollThumbProps } from "./FastScrollThumb";

import classes from "./FastScrollArea.module.css";

export interface FastScrollAreaProps
  extends Omit<ScrollAreaProps, "viewportRef"> {
  /**
   * Content to be rendered inside the scroll area
   */
  children: ReactNode;

  /**
   * Custom icon for the scroll thumb
   * @default <IconArrowsVertical stroke={3} />
   */
  thumbIcon?: ReactNode;

  /**
   * Additional props to pass to the FastScrollThumb component
   */
  thumbProps?: Partial<FastScrollThumbProps>;
}

/**
 * A component that combines ScrollArea and FastScrollThumb, showing the thumb only on mobile devices
 * and regular scrollbars on desktop.
 */
export default function FastScrollArea({
  children,
  thumbIcon = <IconArrowsVertical stroke={3} />,
  thumbProps,
  ...scrollAreaProps
}: FastScrollAreaProps) {
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const os = useOs({ getValueInEffect: false });
  const isMobile = ["ios", "android"].includes(os);

  return (
    <>
      <ScrollArea
        type={isMobile ? "never" : "scroll"}
        scrollbars="y"
        viewportRef={scrollAreaRef}
        {...scrollAreaProps}
        classNames={classes}
      >
        {children}
      </ScrollArea>

      {isMobile && (
        <FastScrollThumb
          type="scroll"
          scrollAreaRef={scrollAreaRef}
          icon={thumbIcon}
          {...thumbProps}
        />
      )}
    </>
  );
}
