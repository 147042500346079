import { Button, Center } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

type FloatingButtonProps = {
  icon?: React.ReactNode;
  label: string;
  onClick?: () => void;
};

function FloatingButton({ icon, label, onClick }: FloatingButtonProps) {
  return (
    <Center
      p="lg"
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        pointerEvents: "none",
      }}
    >
      <Button
        onClick={onClick}
        size="xl"
        style={{
          borderRadius: "100px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          pointerEvents: "auto",
        }}
        leftSection={icon ? icon : <IconPlus />}
      >
        {label}
      </Button>
    </Center>
  );
}

export default FloatingButton;
