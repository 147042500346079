import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { useUploadFiles } from "@xixixao/uploadstuff/react";
import { useMutation } from "convex/react";

import { useUncontrolled } from "@mantine/hooks";

import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";

import { AspectRatio, Button, Image, Paper, Progress } from "@mantine/core";
import { useOs } from "@mantine/hooks";

import {
  IconCameraPlus,
  IconDots,
  IconPhotoPlus,
  IconTrash,
} from "@tabler/icons-react";
import { StoredFile } from "../../convex/util";
import FloatingActionIcon from "./FloatingActionIcon";

export type UploadingFile = {
  url: string;
  storageId: null;
};

type ConvexUploadResponse = {
  storageId: Id<"_storage">;
};

export type PhotoInputHandle = {
  takePhoto: () => void;
  clear: () => void;
};

type PhotoInputProps = {
  value?: StoredFile | UploadingFile | null;
  defaultValue?: StoredFile | null;
  onChange?: (value: StoredFile | UploadingFile | null) => void;

  onUploadBegin?: () => void;
  onUploadProgress?: (p: number) => void;
  onUploadComplete?: () => void;
  onUploadError?: (e: unknown) => void;

  immediateUpload?: boolean;
  onReadyToUpload?: (startUpload: () => Promise<StoredFile | null>) => void;

  defaultAspectRatio?: number;
  aspectRatio?: number;
  disabled?: boolean;
};

const PhotoInput = forwardRef<PhotoInputHandle, PhotoInputProps>(
  function PhotoInput(
    {
      value: _value,
      defaultValue,
      onChange,
      onUploadBegin,
      onUploadProgress,
      onUploadComplete,
      onUploadError,

      defaultAspectRatio = 3,
      aspectRatio: _aspectRatio,
      onReadyToUpload,
      disabled = false,
    }: PhotoInputProps,
    ref
  ) {
    const generateUploadUrl: () => Promise<string> = useMutation(
      api.files.generateUploadUrl
    );

    const os = useOs({ getValueInEffect: false });
    const isMobile =
      ["ios", "android"].includes(os) ||
      (os == `macos` && navigator.maxTouchPoints > 2);

    const [value, handleChange] = useUncontrolled<
      StoredFile | UploadingFile | null
    >({
      value: _value,
      defaultValue,
      finalValue: null,
      onChange,
    });

    // for internal ui
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [aspectRatio, setAspectRatio] = useState(
      _aspectRatio ? _aspectRatio : defaultAspectRatio
    );

    // upload handler
    const { startUpload: _startUpload } = useUploadFiles(
      async () => {
        return await generateUploadUrl();
      },
      {
        onUploadBegin: () => {
          onUploadBegin && onUploadBegin();
        },
        onUploadProgress: onUploadProgress,
        onUploadComplete: async () => {
          if (onUploadComplete) return onUploadComplete();
          else return Promise.resolve();
        },
        onUploadError: onUploadError,
      }
    );
    const startUpload = useCallback(
      async (file: File, url: string) => {
        setUploadInProgress(true);
        const res = await _startUpload([file]);
        setUploadInProgress(false);
        setUploadProgress(0);
        const storageId = (res[0].response as ConvexUploadResponse).storageId;
        const photo = { url: url, storageId: storageId };
        handleChange(photo);
        return photo;
      },
      [setUploadInProgress, setUploadProgress, _startUpload, handleChange]
    );

    // in order to properly release object urls, need to track them with useEffect
    const [file, setFile] = useState<File | null>(null);
    useEffect(() => {
      if (file) {
        const url = window.URL.createObjectURL(file);
        handleChange({
          url: url,
          storageId: null,
        });
        if (onReadyToUpload) {
          onReadyToUpload(() => startUpload(file, url));
        } else {
          startUpload(file, url);
        }

        return () => {
          handleChange(null);
          window.URL.revokeObjectURL(url);
        };
      }
    }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files: FileList = e.target.files as FileList;
      if (files.length > 0) {
        setFile(files[0]);
      } else {
        setFile(null);
        if (!_aspectRatio) setAspectRatio(defaultAspectRatio);
      }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const takePhoto = () => {
      if (!isMobile) return;
      const input = inputRef.current as HTMLInputElement | null;
      input?.setAttribute("capture", "environment");
      input?.showPicker();
    };

    const choosePhoto = () => {
      if (!inputRef.current) return;
      const input = inputRef.current as HTMLInputElement;
      input.removeAttribute("capture");
      input.showPicker();
    };

    const clear = () => {
      if (file != null) {
        // current photo was chosen by file input, so reset it
        // @ts-expect-error ts(2322)
        inputRef.current.value = null;
        setFile(null);
      } else {
        // current photo was provided as default, no need to touch file input
        handleChange(null);
      }
      if (!_aspectRatio) setAspectRatio(defaultAspectRatio);
    };

    useImperativeHandle(ref, () => ({
      takePhoto,
      clear,
    }));

    return (
      <AspectRatio ratio={aspectRatio}>
        <Paper withBorder style={{ overflow: "clip", position: "relative" }}>
          {value?.url ? (
            <>
              <Image
                src={value.url}
                fit="cover"
                style={{ aspectRatio: aspectRatio }}
                onLoad={(e) => {
                  const elem = e.target as HTMLImageElement;
                  if (!_aspectRatio)
                    setAspectRatio(elem.naturalWidth / elem.naturalHeight);
                }}
              />
              <FloatingActionIcon
                disabled={disabled || uploadInProgress}
                color="gray.0"
                position="bottom-right"
                onClick={clear}
                style={{
                  visibility:
                    disabled || uploadInProgress ? "hidden" : "visible",
                }}
              >
                <IconTrash
                  style={{
                    opacity: 0.9,
                    filter: "drop-shadow(0px 1px 2px rgb(0 0 0))",
                  }}
                />
              </FloatingActionIcon>

              <Progress
                size="xs"
                value={uploadProgress}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  visibility: uploadInProgress ? "visible" : "hidden",
                }}
              />
            </>
          ) : (
            <>
              <Button
                disabled={disabled || uploadInProgress}
                variant="subtle"
                onClick={isMobile ? takePhoto : choosePhoto}
                pos="absolute"
                inset={0}
                h="auto"
              >
                {isMobile ? (
                  <IconCameraPlus size={36} stroke={1.5} />
                ) : (
                  <IconPhotoPlus size={36} stroke={1.5} />
                )}
              </Button>
              {isMobile ? (
                <FloatingActionIcon
                  position="bottom-right"
                  disabled={disabled || uploadInProgress}
                  m={6}
                  onClick={choosePhoto}
                >
                  <IconDots />
                </FloatingActionIcon>
              ) : (
                ""
              )}
            </>
          )}
          <input
            disabled={disabled || uploadInProgress}
            ref={inputRef}
            type="file"
            style={{
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
              opacity: "0%",
              display: "none",
            }}
            accept="image/*"
            onChange={onInputChange}
          />
        </Paper>
      </AspectRatio>
    );
  }
);

export default PhotoInput;
