import { MantineSize } from "@mantine/core";

export function mantineSizeSmaller(size: MantineSize, n: number = 1) {
  if (n === 0) {
    return size;
  }
  return mantineSizeSmaller(
    { xs: "xs", sm: "xs", md: "sm", lg: "md", xl: "lg" }[size] as MantineSize,
    n - 1
  );
}

export function mantineSizeLarger(size: MantineSize, n: number = 1) {
  if (n === 0) {
    return size;
  }
  return mantineSizeLarger(
    { xs: "sm", sm: "md", md: "lg", lg: "xl", xl: "xl" }[size] as MantineSize,
    n - 1
  );
}

const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
  timeZone: "UTC",
});

export function formatDate(dateString: string) {
  return dateFormatter.format(new Date(dateString));
}

export function makeSrcSetProp(
  url: string | undefined,
  sizes: number[] = [100, 200, 400, 800, 1600, 3200]
) {
  if (url === undefined) return {};
  const lastIdx = sizes.length - 1;
  const srcSet = sizes
    .map((s, idx) =>
      idx !== lastIdx ? `${url}?tr=w-${s} ${s}w` : `${url} ${s}w`
    )
    .join(", ");
  return { srcSet: srcSet };
}
