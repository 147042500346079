// Displays the location name in dimmed italics if it is provided, otherwise displays "No location" in dimmed normal text

import { Text, TextProps } from "@mantine/core";

type LocationTextProps = TextProps & {
  locationName?: string | null;
};

export default function LocationText({
  locationName,
  ...props
}: LocationTextProps) {
  return (
    <Text
      lineClamp={1}
      fs={locationName ? "italic" : "normal"}
      {...props}
      c={props.c ?? "dimmed"}
    >
      {locationName || "No location"}
    </Text>
  );
}
