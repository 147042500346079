// TODO: handle superseded plants when editing an old entry

import { Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";

import { DatePickerInput } from "@mantine/dates";

import { modals } from "@mantine/modals";
import { ConvexReactClient } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { utcToday } from "../../convex/shared_util";
import { HydratedEntry, StoredFile } from "../../convex/util";
import { getCoverPhotoEntryDate } from "../lib/photo";
import { formatDate } from "../lib/util";
import DialogForm from "./DialogForm";
import EntrySubjectsInput, {
  EntrySubjectId,
  EntrySubjectIdWithNew,
} from "./EntrySubjectsInput";
import MultiplePhotoInput from "./MultiplePhotoInput";
import { PhotoInputHandle } from "./PhotoInput";

type EntryFormValues = {
  date: Date;
  subjects: EntrySubjectIdWithNew[];
  text: string;
  photos: StoredFile[];
};

type EntryDialogProps = {
  photoInput?: React.ForwardedRef<PhotoInputHandle>;
  confirmLabel: string;
  onConfirm?: (values: EntryFormValues) => Promise<void>;
  cancelLabel?: string;
  onCancel?: () => void;
  destroyLabel?: string;
  onDestroy?: () => Promise<void>;
  destroyConfirm?: {
    title: string;
    text: string;
    confirm: string;
    cancel: string;
  };
  initialValues?: Partial<EntryFormValues>;
  allowNewPlants?: boolean;
};

export default function EntryDialog({
  photoInput,
  confirmLabel,
  onConfirm,
  cancelLabel,
  onCancel,
  destroyLabel,
  onDestroy,
  destroyConfirm,
  initialValues,
  allowNewPlants = false,
}: EntryDialogProps) {
  const utcDate = utcToday();
  const form = useForm<EntryFormValues>({
    mode: "uncontrolled",
    initialValues: {
      date: utcDate,
      subjects: [],
      text: "",
      photos: [],
      ...initialValues,
    },
    validate: {},
    onValuesChange: async (values, previous) => {
      if (
        previous.photos.length === 0 &&
        values.photos.length === 1 &&
        !form.isDirty("date")
      ) {
        const dateString = await getCoverPhotoEntryDate({
          file: values.photos[0],
          source: "upload",
        });
        if (dateString) {
          form.setFieldValue("date", new Date(dateString));
        }
      }
    },
  });

  return (
    <DialogForm
      form={form}
      confirmLabel={confirmLabel}
      onConfirm={onConfirm}
      cancelLabel={cancelLabel}
      onCancel={onCancel}
      destroyLabel={destroyLabel}
      onDestroy={onDestroy}
      destroyConfirm={destroyConfirm}
    >
      <DatePickerInput
        firstDayOfWeek={0}
        highlightToday={true}
        {...form.getInputProps("date")}
        key={form.key("date")}
      />
      <EntrySubjectsInput
        placeholder="Plants or locations (optional)"
        {...form.getInputProps("subjects")}
        key={form.key("subjects")}
        allowNewPlants={allowNewPlants}
      />
      <Textarea
        placeholder="Start writing&hellip;"
        {...form.getInputProps("text")}
        key={form.key("text")}
      />
      <MultiplePhotoInput
        ref={photoInput}
        gap="xs"
        {...form.getInputProps("photos")}
        key={form.key("photos")}
      />
    </DialogForm>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function openAddEntryModal(
  convex: ConvexReactClient,
  gardenId: Id<"gardens">,
  subjects?: EntrySubjectId[]
) {
  modals.open({
    title: "Add entry",
    children: (
      <EntryDialog
        confirmLabel="Add"
        onConfirm={async (values) => {
          const { photos, date, ...rest } = values;
          const entryId = convex.mutation(api.gardens.createEntry, {
            photoIds: photos.map((p) => p.storageId),
            gardenId: gardenId,
            date: date.toISOString(),
            ...rest,
          });
          const plantSubjects = rest.subjects.filter((s) =>
            s.type.endsWith("plant")
          );
          const locationSubjects = rest.subjects.filter((s) =>
            s.type.endsWith("location")
          );
          // this should be cached because of the subject picker...
          const allPlants = new Map(
            (
              await convex.query(api.gardens.listPlants, {
                gardenId: gardenId,
              })
            ).map((p) => [p._id, p])
          );

          if (
            plantSubjects.length > 0 &&
            locationSubjects.length === 1 &&
            plantSubjects.filter(
              (p) =>
                p.type === "new-plant" ||
                (p.type === "plant" &&
                  allPlants.get(p.id)?.location?.locationId !==
                    locationSubjects[0].id)
            ).length > 0
          ) {
            const allLocations = await convex.query(api.gardens.listLocations, {
              gardenId: gardenId,
            });

            const plantNames = plantSubjects.map(
              (p) =>
                "\u2018" + allPlants.get(p.id as Id<"plants">)?.name + "\u2019"
            );
            const plantString =
              plantNames.length === 1
                ? plantNames[0]
                : plantNames.slice(0, -1).join(", ") +
                  " and " +
                  plantNames[plantNames.length - 1];
            const onlyLocation = allLocations.filter(
              (doc) => doc._id == locationSubjects[0].id
            )[0];
            let resolve: (value: boolean | PromiseLike<boolean>) => void;
            const modifyPlantLocation = new Promise<boolean>((res) => {
              resolve = res;
            });
            modals.openConfirmModal({
              title: "Automatic location update",
              children: (
                <Text size="sm">
                  Do you want to set the location of {plantString} to &lsquo;
                  {onlyLocation.name}&rsquo;?
                </Text>
              ),
              labels: { confirm: "Yes", cancel: "No" },
              onConfirm: () => resolve(true),
              onCancel: () => resolve(false),
            });
            const entry = await convex.query(api.gardens.getEntry, {
              entryId: await entryId,
            });
            if (await modifyPlantLocation) {
              await Promise.all(
                entry.subjects
                  .filter((s) => s.type === "plant")
                  .map((s) =>
                    convex.mutation(api.gardens.updatePlant, {
                      id: s.doc._id,
                      fields: { locationId: onlyLocation._id },
                    })
                  )
              );
            }
          }
          await entryId;
        }}
        cancelLabel="Cancel"
        initialValues={subjects ? { subjects: subjects } : undefined}
        allowNewPlants={true}
      />
    ),
  });
}

// eslint-disable-next-line react-refresh/only-export-components
export function openEditEntryModal(
  convex: ConvexReactClient,
  doc: HydratedEntry
) {
  modals.open({
    title: "Edit entry",
    children: (
      <EntryDialog
        confirmLabel="Save"
        onConfirm={async (values) => {
          const { photos, date, subjects, ...rest } = values;
          await convex.mutation(api.gardens.updateEntry, {
            id: doc._id,
            photoIds: photos.map((p) => p.storageId),
            date: date.toISOString(),
            subjects: subjects as EntrySubjectId[],
            ...rest,
          });
        }}
        cancelLabel="Cancel"
        initialValues={{
          date: new Date(Date.parse(doc.date)),
          subjects: doc.subjects.map(
            (s) => ({ id: s.doc._id, type: s.type }) as EntrySubjectId
          ),
          text: doc.text,
          photos: doc.photos,
        }}
        onDestroy={async () => {
          await convex.mutation(api.gardens.deleteEntry, { entryId: doc._id });
        }}
        destroyLabel="Delete"
        destroyConfirm={{
          title: `Delete entry ${formatDate(doc.date)}`,
          text: "Are you sure?",
          confirm: "Delete",
          cancel: "Cancel",
        }}
      />
    ),
  });
}
