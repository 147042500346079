import { ActionIcon, MantineSize, Stack, Text } from "@mantine/core";
import { IconSeeding, TablerIcon } from "@tabler/icons-react";
import BaseCard from "./BaseCard";

import { Doc } from "../../convex/_generated/dataModel";
import { mantineSizeSmaller } from "../lib/util";
import BaseRow from "./BaseRow";
import FloatingActionIcon from "./FloatingActionIcon";
import { LayoutType } from "./LayoutChooser";
import LocationText from "./LocationText";

type PlantItemProps = {
  variant: LayoutType;
  size?: MantineSize;
  name: string;
  locationName?: string;
  image?: string;
  sizes?: string;
  archived: Doc<"plants">["archived"];
  onClick?: () => void;
  actionIcons?: { icon: TablerIcon; onClick: () => void }[];
};

export default function PlantItem({
  variant,
  size = "lg",
  name,
  locationName,
  image,
  sizes,
  archived,
  onClick,
  actionIcons = [],
}: PlantItemProps) {
  switch (variant) {
    case "cards":
      return (
        <BaseCard
          image={image}
          sizes={sizes}
          onClick={onClick}
          placeholder={
            <IconSeeding size="50" color="var(--mantine-color-gray-5)" />
          }
          vignetting="top"
        >
          <Stack pos="absolute" inset={0} gap={0} p="sm" justify="flex-start">
            <Text
              size={size}
              fw={700}
              c={archived ? "gray.4" : "gray.0"}
              lineClamp={1}
            >
              {name}
            </Text>
            <LocationText
              c={archived ? "dimmed" : "gray.0"}
              size={mantineSizeSmaller(size, 2)}
              locationName={locationName}
            />
          </Stack>
          {actionIcons.map(({ icon: Icon, onClick }) => {
            return (
              <FloatingActionIcon
                key={Icon.displayName}
                position="top-right"
                color="gray.0"
                variant="subtle"
                size="md"
                p={3}
                m="sm"
                style={{ borderRadius: "100px", pointerEvents: "auto" }}
                onClick={onClick}
              >
                <Icon />
              </FloatingActionIcon>
            );
          })}
        </BaseCard>
      );

    case "list":
      return (
        <BaseRow
          image={image}
          placeholder={
            <IconSeeding size={36} color="var(--mantine-color-gray-6)" />
          }
          onClick={onClick}
        >
          <Stack flex={1} style={{ rowGap: 0 }}>
            <Text {...(archived ? { c: "dimmed" } : {})} lineClamp={1}>
              {name}
            </Text>
            <LocationText
              c={archived ? "dimmed" : undefined}
              lineClamp={1}
              size="xs"
              locationName={locationName}
            />
          </Stack>
          {actionIcons.map(({ icon: Icon, onClick }) => {
            return (
              <ActionIcon
                key={Icon.displayName}
                color="gray.7"
                variant="subtle"
                style={{ borderRadius: "100px" }}
                onClick={onClick}
              >
                <Icon size={18} />
              </ActionIcon>
            );
          })}
        </BaseRow>
      );
  }
}
