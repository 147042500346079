import { ActionIcon, Group, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import LayoutChooser, { LayoutType } from "./LayoutChooser";

export type DetailSectionHeaderProps = {
  title: string;
  layoutOptions?: LayoutType[];
  setLayout?: (layout: LayoutType) => void;
  activeLayout?: LayoutType;
  onAddClick?: () => void;
};

export default function DetailSectionHeader({
  title,
  layoutOptions = [],
  setLayout,
  activeLayout,
  onAddClick,
}: DetailSectionHeaderProps) {
  return (
    <Group justify="space-between" align="center">
      <Group gap={4} align="center">
        <Title order={3}>{title}</Title>
        {layoutOptions?.length > 0 && setLayout && activeLayout && (
          <LayoutChooser
            options={layoutOptions}
            setLayout={setLayout}
            activeLayout={activeLayout}
          />
        )}
      </Group>
      <Group gap={4} align="center">
        {onAddClick && (
          <ActionIcon variant="light" size="sm" onClick={onAddClick}>
            <IconPlus size={16} />
          </ActionIcon>
        )}
      </Group>
    </Group>
  );
}
