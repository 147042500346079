import dayjs from "dayjs";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);

import ExifReader from "exifreader";
import { toUTCDate } from "../../convex/shared_util";
import { CoverPhoto } from "../../convex/util";

export async function getExifDateTimeOriginal(url: string) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error();
  }

  const blob = await response.blob();
  const arrayBuffer = await blob.arrayBuffer();

  const tags = ExifReader.load(arrayBuffer);

  const exifDate =
    "DateTimeOriginal" in tags
      ? dayjs(
          // @ts-expect-error implicit any
          tags.DateTimeOriginal.value[0] as string,
          "YYYY:MM:DD HH:mm:ss"
        ).toDate()
      : new Date(); // fallback to today

  const utcDate = toUTCDate(exifDate);
  return utcDate.toISOString();
}

export async function getCoverPhotoEntryDate(coverPhoto: CoverPhoto) {
  let coverPhotoEntryDate: string | null = null;
  if (coverPhoto?.source === "upload") {
    coverPhotoEntryDate = await getExifDateTimeOriginal(coverPhoto?.file.url);
  }
  return coverPhotoEntryDate;
}
