import { rem } from "@mantine/core";

interface AddressBookIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  size?: number | string;
}

export default function PlantingsIcon({
  size,
  style,
  ...others
}: AddressBookIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d="M 5.2774566,16.921389 C 8.2889004,14.959677 10.404178,8.8119484 14.857804,6.782659" />
      <path d="M 4.8743187,22.23683 C 6.7049557,14.505085 2.1480316,6.911003 4.3829892,1.6934759" />
      <path d="m 16.009125,12.156031 c 0.744959,-0.450824 2.690566,-1.508948 3.196617,-4.4590594 0.61657,-3.594399 1.373662,-3.9019618 1.914451,-5.2610958 h -3.414146 c -7.655036,0 -10.5832459,2.6979141 -10.5951538,6.417128 0,0.743842 0.5935222,2.4028462 1.6456279,3.8856862 0,0 1.3486579,0.200803 1.8025449,0.221966 1.58386,0.07384 4.005066,0.06984 5.450059,-0.804625 z" />
    </svg>
  );
}
