import { useContext } from "react";

import { useConvex, useQuery } from "convex/react";

import { Box, Group, Stack, Title } from "@mantine/core";

import { api } from "../../convex/_generated/api";

import FastScrollArea from "./FastScrollArea";
import FloatingButton from "./FloatingButton";
import GardenContext from "./GardenContext";
import LayoutChooser, { useLayoutState } from "./LayoutChooser";
import LocationDetail from "./LocationDetail";
import { openAddLocationModal, openEditLocationModal } from "./LocationDialog";
import LocationList from "./LocationList";
import { usePile } from "./NavPile";

function LocationsPane() {
  const [push] = usePile();
  const currentGarden = useContext(GardenContext);
  const locations = useQuery(
    api.gardens.listLocations,
    currentGarden ? { gardenId: currentGarden._id } : "skip"
  );
  const plants = useQuery(
    api.gardens.listPlants,
    currentGarden ? { gardenId: currentGarden._id } : "skip"
  );

  const [layout, setLayout] = useLayoutState("locations-pane", "cards");

  const convex = useConvex();

  if (!currentGarden) {
    return <></>;
  }

  return (
    <Box pos="absolute" inset={0} style={{ zIndex: 0 }}>
      <FastScrollArea pos="absolute" inset={0}>
        <Box pos="relative" inset={0} p="md" pb="100px">
          <Group justify="space-between" align="center">
            <Title order={2}>Locations</Title>
            <LayoutChooser activeLayout={layout} setLayout={setLayout} />
          </Group>
          <Stack mt="md" gap={layout == "cards" ? "md" : "sm"}>
            {locations === undefined && <>No locations yet</>}
            <LocationList
              variant={layout}
              locations={locations || []}
              plants={plants || []}
              onItemClick={(doc) => {
                push(<LocationDetail location={doc} />, doc.name);
              }}
              onEditClick={(doc) => openEditLocationModal(convex, doc)}
            />
          </Stack>
        </Box>
      </FastScrollArea>

      <FloatingButton
        label="Add location"
        onClick={() => openAddLocationModal(convex, currentGarden._id)}
      />
    </Box>
  );
}

export default LocationsPane;
