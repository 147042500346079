import { Button, ButtonProps } from "@mantine/core";

import {
  IconGripVertical,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";

import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type SortableButtonProps = ButtonProps & {
  id: UniqueIdentifier;
  sortDirection: "ascending" | "descending";
  toggleSortDirection: () => void;
};

export default function SortableButton({
  sortDirection,
  toggleSortDirection,
  ...props
}: SortableButtonProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const SortIcon =
    sortDirection == "ascending" ? IconSortAscending : IconSortDescending;
  return (
    <Button
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      variant="light"
      radius="sm"
      size="compact-sm"
      color="black"
      bg="gray.0"
      justify="left"
      leftSection={<SortIcon size={16} stroke={1.7} />}
      rightSection={<IconGripVertical size={16} stroke={1} />}
      styles={{
        label: { fontWeight: 400, flex: 1, marginLeft: -4 },
      }}
      onClick={toggleSortDirection}
    >
      {props.children}
    </Button>
  );
}
