import { TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { modals } from "@mantine/modals";
import { ConvexReactClient } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { StoredFile } from "../../convex/util";
import DialogForm from "./DialogForm";
import PhotoInput from "./PhotoInput";

type GardenFormValues = {
  name: string;
  coverPhoto: StoredFile | null;
};

type GardenDialogProps = {
  confirmLabel: string;
  onConfirm?: (values: GardenFormValues) => Promise<void>;
  cancelLabel?: string;
  onCancel?: () => void;
  initialValues?: GardenFormValues;
};

export default function GardenDialog({
  confirmLabel,
  onConfirm,
  cancelLabel,
  onCancel,
  initialValues,
}: GardenDialogProps) {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: initialValues,
    validate: {
      coverPhoto: (value) =>
        value != null && value.storageId == null
          ? "Photo still uploading"
          : null,
    },
  });

  return (
    <DialogForm
      form={form}
      confirmLabel={confirmLabel}
      onConfirm={onConfirm}
      cancelLabel={cancelLabel}
      onCancel={onCancel}
    >
      <PhotoInput
        {...form.getInputProps("coverPhoto")}
        key={form.key("coverPhoto")}
      />
      <TextInput
        placeholder="Garden nam&#8203;e" // stupid hack to prevent Safari autofill
        {...form.getInputProps("name")}
        key={form.key("name")}
      />
    </DialogForm>
  );
}

export function openAddGardenModal(
  convex: ConvexReactClient,
  onCreate?: (gardenId: Id<"gardens">) => Promise<void>
) {
  modals.open({
    title: "Create new garden",
    children: (
      <GardenDialog
        confirmLabel="Create"
        onConfirm={async (values) => {
          const gardenId = await convex.mutation(api.gardens.createGarden, {
            coverPhotoId: values.coverPhoto?.storageId || null,
            name: values.name,
          });
          if (onCreate) await onCreate(gardenId);
        }}
        cancelLabel="Cancel"
      />
    ),
  });
}
