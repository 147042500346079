import { forwardRef, useImperativeHandle, useRef } from "react";

import { Card, Image, Stack, StackProps } from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";

import { IconTrash } from "@tabler/icons-react";

import { Id } from "../../convex/_generated/dataModel";
import { StoredFile } from "../../convex/util";

import FloatingActionIcon from "./FloatingActionIcon";
import openFullscreenPhoto from "./FullscreenPhoto";
import PhotoInput, { PhotoInputHandle } from "./PhotoInput";

type MultiplePhotoInputProps = {
  value?: StoredFile[];
  defaultValue?: StoredFile[];
  onChange?: (value: StoredFile[]) => void;
  aspectRatio?: number;
} & Omit<StackProps, "defaultValue">;

const MultiplePhotoInput = forwardRef<
  PhotoInputHandle,
  MultiplePhotoInputProps
>(function MultiplePhotoInput(
  {
    value: _value,
    defaultValue = [],
    onChange,
    ...props
  }: MultiplePhotoInputProps,
  ref
) {
  const [value, handleChange] = useUncontrolled<StoredFile[]>({
    value: _value,
    defaultValue,
    onChange,
  });

  const removePhoto = (storageId: Id<"_storage">) => {
    if (value)
      handleChange(value.filter((photo) => photo.storageId != storageId));
  };
  const photoInput = useRef<PhotoInputHandle>(null);

  const uploadedPhotos = value.map((p) => {
    return (
      <Card key={p.storageId} shadow="xs">
        <Card.Section>
          <Image
            src={p.url}
            onClick={() => {
              openFullscreenPhoto(p.url);
            }}
          />
          <FloatingActionIcon
            color="gray.0"
            position="bottom-right"
            onClick={() => removePhoto(p.storageId)}
          >
            <IconTrash
              style={{
                opacity: 0.9,
                filter: "drop-shadow(0px 1px 2px rgb(0 0 0))",
              }}
            />
          </FloatingActionIcon>
        </Card.Section>
      </Card>
    );
  });

  useImperativeHandle(ref, () => ({
    takePhoto: () => photoInput.current?.takePhoto(),
    clear: () => photoInput.current?.clear(),
  }));

  return (
    <Stack {...props}>
      {uploadedPhotos}
      <PhotoInput
        ref={photoInput}
        onReadyToUpload={async (startUpload) => {
          const p = await startUpload();
          if (p) handleChange([...value, p]);
          photoInput.current?.clear();
        }}
      />
    </Stack>
  );
});

export default MultiplePhotoInput;
