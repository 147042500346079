import { Text, TextProps } from "@mantine/core";

type PlantCountTextProps = Omit<TextProps, "children"> & {
  count: number | undefined;
};

export default function PlantCountText({
  count,
  fs = "italic",
  c = "dimmed",
  lineClamp = 1,
  ...props
}: PlantCountTextProps) {
  return (
    <Text fs={fs} c={c} lineClamp={lineClamp} {...props}>
      {count !== undefined && count > 1 && <>{count} plants</>}
      {count !== undefined && count === 1 && <>1 plant</>}
    </Text>
  );
}
