import { ActionIcon, Flex, Group, Stack, Text } from "@mantine/core";
import { IconAlignLeft, IconNotes, TablerIcon } from "@tabler/icons-react";

import { HydratedEntry, StoredFile } from "../../convex/util";
import BaseCard from "./BaseCard";
import BaseRow from "./BaseRow";
import FloatingActionIcon from "./FloatingActionIcon";
import { LayoutType } from "./LayoutChooser";
import SubjectPills from "./SubjectPills";

type EntryItemProps = {
  variant: LayoutType;
  date: string;
  subjects: HydratedEntry["subjects"];
  text: string;
  photos: StoredFile[];
  onClick?: () => void;
  actionIcons?: { icon: TablerIcon; onClick: () => void }[];
};

function EntryItemCard({
  date,
  subjects,
  text,
  photos,
  onClick,
  actionIcons = [],
}: Omit<EntryItemProps, "variant">) {
  return (
    <BaseCard
      images={photos.map((p) => p.url)}
      onClick={onClick}
      placeholder={<IconNotes size="50" color="var(--mantine-color-gray-5)" />}
      vignetting="both"
    >
      <Stack
        pos="absolute"
        inset={0}
        p="sm"
        mb={photos.length > 1 ? "xs" : 0}
        justify="space-between"
      >
        <Flex gap="xs" wrap="wrap" style={{ pointerEvents: "auto" }}>
          <SubjectPills subjects={subjects} clickable />
        </Flex>
        <Flex gap="sm" align="baseline">
          <Text size="sm" c="gray.0" lineClamp={3}>
            <strong>{date}</strong> {text && <> &mdash; {text}</>}
          </Text>
        </Flex>
      </Stack>
      {actionIcons.map(({ icon: Icon, onClick }) => {
        return (
          <FloatingActionIcon
            key={Icon.displayName}
            position="top-right"
            color="gray.0"
            variant="subtle"
            size="md"
            p={3}
            style={{ borderRadius: "100px", pointerEvents: "auto" }}
            onClick={onClick}
          >
            <Icon />
          </FloatingActionIcon>
        );
      })}
    </BaseCard>
  );
}

function EntryItemRow({
  date,
  subjects,
  text,
  photos,
  onClick,
  actionIcons = [],
}: Omit<EntryItemProps, "variant">) {
  return (
    <BaseRow
      images={photos.map((p) => p.url)}
      placeholder={
        <IconAlignLeft size={36} color="var(--mantine-color-gray-6)" />
      }
      onClick={onClick}
    >
      <Stack
        flex={1}
        gap={6}
        style={{ maxWidth: "calc(100% - 134px)", overflow: "clip" }}
      >
        <Text size="sm" lineClamp={1}>
          <strong>{date}</strong>
          {text && <> &mdash; {text}</>}
        </Text>
        {subjects.length > 0 && (
          <Group gap="xs" wrap="nowrap" pb={4}>
            <SubjectPills subjects={subjects} size="xs" />
          </Group>
        )}
      </Stack>
      {actionIcons.map(({ icon: Icon, onClick }) => {
        return (
          <ActionIcon
            key={Icon.displayName}
            color="gray.7"
            variant="subtle"
            style={{ borderRadius: "100px" }}
            onClick={onClick}
          >
            <Icon size={18} />
          </ActionIcon>
        );
      })}
    </BaseRow>
  );
}

export default function EntryItem({ variant, ...props }: EntryItemProps) {
  if (variant == "cards") {
    return <EntryItemCard {...props} />;
  } else {
    return <EntryItemRow {...props} />;
  }
}
