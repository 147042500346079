import { ReactNode } from "react";

import {
  AspectRatio,
  Button,
  Center,
  DefaultMantineColor,
  Group,
  Image,
  Paper,
  StyleProp,
} from "@mantine/core";
import { makeSrcSetProp } from "../lib/util";

type BaseRowProps = {
  children?: ReactNode;
  image?: string;
  images?: string[];
  onClick?: () => void;
  height?: number;
  aspectRatio?: number;
  placeholder: ReactNode;
  bg?: StyleProp<DefaultMantineColor>;
};

export default function BaseRow({
  children,
  image,
  images = [],
  onClick,
  height = 64,
  aspectRatio = 4 / 3,
  placeholder,
  bg = "gray.0",
}: BaseRowProps) {
  const width = Math.ceil(height * aspectRatio);

  return (
    <Paper
      radius="sm"
      shadow="xs"
      pos="relative" // sets stacking context for children
      bg={bg}
      style={{ overflow: "clip", height: "100%" }}
    >
      {onClick && (
        <Button
          variant="transparent"
          pos="absolute"
          onClick={onClick}
          inset={0}
          h="auto"
        />
      )}
      <Group gap="xs" pr="xs" style={{ height: height }} wrap="nowrap">
        <AspectRatio ratio={aspectRatio}>
          {(image || images.length == 1) && (
            <Image
              {...makeSrcSetProp(image || images[0])}
              sizes={`${width}px`}
              loading="lazy"
              style={{ height: height, width: width }}
            />
          )}
          {images.length > 1 && (
            <Image
              {...makeSrcSetProp(images[0])}
              loading="lazy"
              sizes={`${width}px`}
              style={{ height: height, width: width }}
            />
          )}
          {!image && images.length == 0 && (
            <Center bg="gray.3" style={{ height: height, width: width }}>
              {placeholder}
            </Center>
          )}
        </AspectRatio>
        {children}
      </Group>
    </Paper>
  );
}
