import { ReactNode, useRef } from "react";

import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";

import { Carousel } from "@mantine/carousel";
import {
  AspectRatio,
  Box,
  Button,
  Center,
  DefaultMantineColor,
  Image,
  Overlay,
  Paper,
  StyleProp,
} from "@mantine/core";

import { makeSrcSetProp } from "../lib/util";
import classes from "./BaseCard.module.css";

type BaseCardProps = {
  children?: ReactNode;
  image?: string;
  images?: string[];
  sizes?: string;
  vignetting?: "top" | "bottom" | "both" | "none";
  onClick?: () => void;
  aspectRatio?: number | "auto";
  placeholder: ReactNode;
  bg?: StyleProp<DefaultMantineColor>;
};

export default function BaseCard({
  children,
  image,
  images = [],
  sizes,
  vignetting = "none",
  onClick,
  aspectRatio = 3 / 2,
  placeholder,
  bg = "gray.6",
}: BaseCardProps) {
  const wheelGestures = useRef(WheelGesturesPlugin());
  if (images.length > 0 && image) throw new Error();
  if (images.length === 1) {
    image = images[0];
    images = [];
  }
  let gradient: string | undefined = undefined;
  switch (vignetting) {
    case "top":
      gradient =
        "linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0) 120px), linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0) 80px)";
      break;
    case "bottom":
      gradient =
        "linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0) 120px), linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0) 80px)";
      break;
    case "both":
      gradient =
        "linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 120px), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 80px), linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0) 120px), linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0) 80px)";
      break;
    case "none":
      gradient = undefined;
      break;
  }
  const background = (
    <>
      {image && (
        <>
          <Image {...makeSrcSetProp(image)} sizes={sizes} loading="lazy" />
          {onClick && (
            <Button
              variant="transparent"
              pos="absolute"
              onClick={onClick}
              inset={0}
              h="auto"
            />
          )}
          <Overlay
            gradient={gradient}
            backgroundOpacity={0}
            style={{ pointerEvents: "none" }}
          />
        </>
      )}
      {images.length > 0 && (
        <>
          <Carousel
            withControls={false}
            withIndicators={images.length > 1}
            loop={true}
            skipSnaps={true}
            draggable={true}
            plugins={[wheelGestures.current]}
            classNames={classes}
          >
            {images.map((url, idx) => {
              return (
                <Carousel.Slide key={idx}>
                  <Image
                    {...makeSrcSetProp(url)}
                    sizes={sizes}
                    loading="lazy"
                    style={{ aspectRatio: aspectRatio }}
                  />
                  {onClick && (
                    <Button
                      variant="transparent"
                      pos="absolute"
                      onClick={onClick}
                      inset={0}
                      h="auto"
                    />
                  )}
                  <Overlay
                    gradient={gradient}
                    backgroundOpacity={0}
                    style={{ pointerEvents: "none" }}
                  />
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </>
      )}
      {!image && images.length === 0 && (
        <>
          <Center>{placeholder}</Center>
          {onClick && (
            <Button
              variant="transparent"
              pos="absolute"
              onClick={onClick}
              inset={0}
              h="auto"
            />
          )}
        </>
      )}
    </>
  );
  return (
    <Paper
      radius="md"
      shadow="xs"
      pos="relative" // I think this is to provide a context for position: absolute inside
      style={{ overflow: "clip" }}
      bg={bg}
    >
      {aspectRatio === "auto" ? (
        background
      ) : (
        <AspectRatio ratio={aspectRatio}>{background}</AspectRatio>
      )}

      <Box
        pos="absolute"
        inset={0}
        style={{
          zIndex: 300 /* overlay has zIndex 200 */,
          pointerEvents: "none",
        }}
      >
        {children}
      </Box>
    </Paper>
  );
}
