import { Carousel } from "@mantine/carousel";
import { Image } from "@mantine/core";
import { randomId } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { useRef } from "react";
import { makeSrcSetProp } from "../lib/util";

import classes from "./Lightbox.module.css";

type LightboxProps = {
  images: string[];
  initialImage: number;
  close?: () => void;
};

export function Lightbox(props: LightboxProps) {
  const wheelGestures = useRef(WheelGesturesPlugin());

  return (
    <Carousel
      align="start"
      slideGap="lg"
      initialSlide={props.initialImage}
      skipSnaps={true}
      plugins={[wheelGestures.current]}
      styles={{
        slide: {
          display: "flex",
          align: "center",
          justifyContent: "center",
        },
      }}
      classNames={classes}
    >
      {props.images.map((src) => {
        return (
          <Carousel.Slide>
            <Image
              radius="md"
              {...makeSrcSetProp(src)}
              sizes="100vw"
              fit="contain"
            />
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export default function openLightbox(images: string[], initialImage: number) {
  const modalId = randomId();
  modals.open({
    modalId: modalId,
    centered: true,
    withCloseButton: false,
    closeOnClickOutside: true,
    overlayProps: { backgroundOpacity: 0.8, blur: 10 },
    styles: {
      content: {
        backgroundColor: "transparent",
        pointerEvents: "all",
      },
    },
    children: (
      <Lightbox
        images={images}
        initialImage={initialImage}
        close={() => modals.close(modalId)}
      />
    ),
  });
}
