import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { HydratedEntry } from "../../convex/util";

import { IconNotes, IconPencil, IconPhoto } from "@tabler/icons-react";
import { useConvex, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { formatDate } from "../lib/util";
import BaseCard from "./BaseCard";
import DebugTooltip from "./DebugTooltip";
import { openEditEntryModal } from "./EntryDialog";
import openFullscreenPhoto from "./FullscreenPhoto";
import PileBackLink from "./PileBackLink";
import SubjectPills from "./SubjectPills";

export type EntryDetailProps = {
  entry: HydratedEntry;
};

export default function EntryDetail({ entry: propsEntry }: EntryDetailProps) {
  // TODO: pop this if the entry gets deleted
  const liveEntry = useQuery(api.gardens.getEntry, { entryId: propsEntry._id });
  const entry = liveEntry ? liveEntry : propsEntry;

  const convex = useConvex();

  return (
    // outer box creates stacking context
    <Box pos="absolute" inset={0} style={{ zIndex: 0 }}>
      <Box
        pos="absolute"
        inset={0}
        p="md"
        bg="white"
        style={{ overflowY: "scroll", overflowX: "clip" }}
      >
        <Stack gap={4}>
          <PileBackLink />
          <Group justify="space-between" align="center" wrap="nowrap">
            <Group align="center" gap={4} wrap="nowrap">
              <DebugTooltip label={entry._id}>
                <Title order={2} lineClamp={1}>
                  {formatDate(entry.date)}
                </Title>
              </DebugTooltip>
              <IconNotes size={20} />
            </Group>
            <ActionIcon
              size="sm"
              variant="light"
              onClick={() => openEditEntryModal(convex, entry)}
            >
              <IconPencil size={16} />
            </ActionIcon>
          </Group>
        </Stack>

        <Stack mt="md" gap="lg">
          {entry.text && <Text>{entry.text}</Text>}
          <Flex gap="xs" wrap="wrap">
            <SubjectPills subjects={entry.subjects} clickable />
          </Flex>
          {entry.photos.map((p) => {
            return (
              <BaseCard
                key={p.storageId}
                aspectRatio="auto"
                image={p.url}
                sizes="100vw"
                placeholder={
                  <IconPhoto size="50" color="var(--mantine-color-gray-5)" />
                }
                onClick={() => openFullscreenPhoto(p.url)}
              />
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
}
