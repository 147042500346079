import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { useUploadFiles } from "@xixixao/uploadstuff/react";
import { useMutation } from "convex/react";

import { useUncontrolled } from "@mantine/hooks";

import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";

import { StoredFile, UploadingFile } from "../../convex/util";

type ConvexUploadResponse = {
  storageId: Id<"_storage">;
};

export type FileUploaderHandle = {
  clear: () => void;
  openCamera: (camera?: "user" | "environment") => void;
  showPicker: () => void;
};

type FileUploaderProps = {
  value?: StoredFile | UploadingFile | null;
  defaultValue?: StoredFile | null;
  onChange?: (value: StoredFile | UploadingFile | null) => void;

  onUploadReady?: (
    startUpload: () => Promise<StoredFile | null>,
    file: UploadingFile
  ) => void;
  onUploadBegin?: () => void;
  onUploadProgress?: (p: number) => void;
  onUploadComplete?: (storedFile: StoredFile) => Promise<void>;
  onUploadError?: (e: unknown) => void;

  disabled?: boolean;
  accept?: string;
};

const FileUploader = forwardRef<FileUploaderHandle, FileUploaderProps>(
  function FileUploader(
    {
      value: _value,
      defaultValue,
      onChange,

      onUploadReady,
      onUploadBegin,
      onUploadProgress,
      onUploadComplete: _onUploadComplete,
      onUploadError,

      disabled = false,
      accept = "image/*",
    }: FileUploaderProps,
    thisRef
  ) {
    const generateUploadUrl: () => Promise<string> = useMutation(
      api.files.generateUploadUrl
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, handleChange] = useUncontrolled<StoredFile | UploadingFile | null>(
      {
        value: _value,
        defaultValue,
        finalValue: null,
        onChange,
      }
    );

    // upload handler
    const { startUpload: _startUpload } = useUploadFiles(
      async () => {
        return await generateUploadUrl();
      },
      {
        onUploadBegin: onUploadBegin,
        onUploadProgress: onUploadProgress,
        onUploadError: onUploadError,
      }
    );
    const startUpload = useCallback(
      async (file: File, url: string) => {
        const res = await _startUpload([file]);
        const storageId = (res[0].response as ConvexUploadResponse).storageId;
        const storedFile = { url: url, storageId: storageId };
        handleChange(storedFile);
        if (_onUploadComplete) await _onUploadComplete(storedFile);
        return storedFile;
      },
      [_startUpload, handleChange, _onUploadComplete]
    );

    // in order to properly release object urls, need to track them with useEffect
    const [file, setFile] = useState<File | null>(null);
    useEffect(() => {
      if (file) {
        const url = window.URL.createObjectURL(file);
        handleChange({
          url: url,
          storageId: null,
        });
        if (onUploadReady) {
          onUploadReady(() => startUpload(file, url), {
            url: url,
            storageId: null,
          });
        } else {
          startUpload(file, url);
        }

        return () => {
          handleChange(null);
          window.URL.revokeObjectURL(url);
        };
      }
    }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files: FileList = e.target.files as FileList;
      if (files.length > 0) {
        setFile(files[0]);
      } else {
        setFile(null);
      }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const clear = () => {
      if (file != null) {
        // current file was chosen by file input, so reset it
        // @ts-expect-error ts(2322)
        inputRef.current.value = null;
        setFile(null);
      } else {
        // current photo was provided as default, no need to touch file input
        handleChange(null);
      }
    };

    const openCamera = (camera: "user" | "environment" = "environment") => {
      const input = inputRef.current as HTMLInputElement | null;
      input?.setAttribute("capture", camera);
      input?.showPicker();
    };

    const showPicker = () => {
      if (!inputRef.current) return;
      const input = inputRef.current as HTMLInputElement;
      input.removeAttribute("capture");
      input.showPicker();
    };

    useImperativeHandle(thisRef, () => ({
      clear,
      openCamera,
      showPicker,
    }));

    return (
      <input
        disabled={disabled}
        ref={inputRef}
        type="file"
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          opacity: "0%",
          display: "none",
        }}
        accept={accept}
        onChange={onInputChange}
      />
    );
  }
);

export default FileUploader;
