import { useContext, useState } from "react";

import { useQuery } from "convex/react";

import {
  CheckIcon,
  Combobox,
  ComboboxProps,
  Group,
  Pill,
  PillsInput,
  Text,
  useCombobox,
} from "@mantine/core";
import { randomId, useUncontrolled } from "@mantine/hooks";

import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";

import { IconMapPin, IconPlus, IconSeeding } from "@tabler/icons-react";
import { HydratedLocation, HydratedPlant } from "../../convex/util";
import GardenContext from "./GardenContext";

export type EntrySubjectId =
  | { type: "plant"; id: Id<"plants"> }
  | { type: "location"; id: Id<"locations"> };
export type EntrySubjectIdWithNew =
  | { type: "plant"; id: Id<"plants"> }
  | { type: "location"; id: Id<"locations"> }
  | { type: "new-plant"; id: string; name: string };

type EntrySubject =
  | {
      type: "plant";
      id: Id<"plants">;
      sortKey: string[];
      searchKey: string[];
      depth: 0;
      label: string;
      sublabel?: string;
      record: HydratedPlant;
    }
  | {
      type: "location";
      id: Id<"locations">;
      sortKey: string[];
      searchKey: string[];
      depth: number;
      label: string;
      sublabel?: string;
      record: HydratedLocation;
    };

type EntrySubjectsInputProps = {
  value?: EntrySubjectIdWithNew[];
  defaultValue?: EntrySubjectIdWithNew[];
  onChange?: (value: EntrySubjectIdWithNew[]) => void;
  placeholder?: string;
  allowNewPlants?: boolean;
} & ComboboxProps;

export default function EntrySubjectsInput({
  value: _value,
  defaultValue,
  onChange,
  placeholder,
  allowNewPlants = false,
  ...props
}: EntrySubjectsInputProps) {
  const currentGarden = useContext(GardenContext);

  const locations =
    useQuery(
      api.gardens.listLocations,
      currentGarden ? { gardenId: currentGarden._id } : "skip"
    ) || [];

  const plants =
    useQuery(
      api.gardens.listPlants,
      currentGarden ? { gardenId: currentGarden._id } : "skip"
    ) || [];

  const normalize = (list: string[]) => {
    return list.map((s) => s.toLowerCase().trim());
  };
  const toArray = (s: string | undefined) => (s ? [s] : []);

  const allSubjects: EntrySubject[] = [
    ...locations.map((doc) => {
      return {
        type: "location",
        id: doc._id,
        depth: doc.depth,
        searchKey: normalize([doc.name]),
        label: doc.name,
        record: doc,
      } as EntrySubject;
    }),
    ...plants
      .map((doc) => {
        return {
          type: "plant",
          id: doc._id,
          depth: 0,
          searchKey: normalize([doc.name, ...toArray(doc.location?.name)]),
          sortKey: normalize([doc.name, ...toArray(doc.location?.name)]),
          label: doc.name,
          sublabel: doc.location?.name,
          record: doc,
        } as EntrySubject;
      })
      .sort((a, b) => {
        for (let i = 0; i < a.sortKey.length; i++) {
          const cmp = a.sortKey[i].localeCompare(b.sortKey[i]);
          if (cmp != 0) return cmp;
        }
        return 0;
      }),
  ];
  const subjectsById = new Map(allSubjects.map((s) => [s.id, s]));

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const [search, setSearch] = useState("");
  const [value, handleChange] = useUncontrolled<EntrySubjectIdWithNew[]>({
    value: _value,
    defaultValue,
    onChange,
  });

  const handleValueSelect = (_selectedId: string) => {
    const selectedId = _selectedId as
      | Id<"plants">
      | Id<"locations">
      | "$createPlant"; // TODO fix stupid workaround?
    if (selectedId == "$createPlant") {
      handleChange([
        ...value,
        { type: "new-plant", id: randomId(), name: search },
      ]);
    } else {
      const updateValue = (current: EntrySubjectIdWithNew[]) =>
        current.map((s) => s.id).includes(selectedId)
          ? current.filter((v) => v.id !== selectedId)
          : [
              ...current,
              {
                id: selectedId,
                type: (subjectsById.get(selectedId) as EntrySubject).type,
              } as EntrySubjectIdWithNew,
            ];
      handleChange(updateValue(value));
    }
    setSearch("");
    combobox.closeDropdown();
  };

  const handleValueRemove = (val: string) => {
    const updateValue = (current: EntrySubjectIdWithNew[]) =>
      current.filter((v) => v.id !== val);
    handleChange(updateValue(value));
  };

  const pills = value.map((s) => {
    const item = subjectsById.get(s.id as Id<"plants"> | Id<"locations">);
    if (!item && !("name" in s)) return;
    return (
      <Pill
        key={s.id}
        withRemoveButton
        onRemove={() => handleValueRemove(s.id)}
        mx={-6}
        styles={{
          label: { margin: 6, gap: 2, display: "flex", alignItems: "center" },
          remove: { opacity: 0.4 },
          root: !item ? { backgroundColor: "#F4FCE3" } : {},
        }}
      >
        {s.type.endsWith("plant") && <IconSeeding size={12} />}
        {s.type.endsWith("location") && <IconMapPin size={12} />}
        <Text size="xs">{item?.label || ("name" in s && s.name)}</Text>
      </Pill>
    );
  });

  const splittedSearch = search.toLowerCase().trim().split(" ");

  const options = allSubjects
    .filter((item) => {
      return splittedSearch.every((searchWord) =>
        item.searchKey.some((word) => word.includes(searchWord))
      );
    })
    .map((item) => (
      <Combobox.Option
        value={item.id}
        key={item.id}
        active={value.map((s) => s.id).includes(item.id)}
      >
        <Group justify="space-between" wrap="nowrap">
          <Group gap="xs" wrap="nowrap">
            {item.type == "plant" && <IconSeeding size={14} />}
            {item.type == "location" && <IconMapPin size={14} />}
            <Group gap="xs" align="baseline" wrap="nowrap">
              <Text size="sm" lineClamp={1}>
                {String.fromCharCode(0x00a0)
                  .repeat(item.depth * 2)
                  .concat(item.label)}
              </Text>
              {item.sublabel && (
                <Text size="xs" fs="italic" c="dimmed" lineClamp={1}>
                  {item.sublabel}
                </Text>
              )}
            </Group>
          </Group>
          {value.map((s) => s.id).includes(item.id) ? (
            <CheckIcon size={12} opacity={0.4} />
          ) : null}
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox {...props} store={combobox} onOptionSubmit={handleValueSelect}>
      <Combobox.DropdownTarget>
        <PillsInput
          size="sm"
          onClick={() => combobox.openDropdown()}
          onChange={() => combobox.openDropdown()}
        >
          <Pill.Group style={{ columnGap: 16, rowGap: 6 }}>
            {pills}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder={value.length > 0 ? "" : placeholder}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    if (value.length > 0) {
                      handleValueRemove(value[value.length - 1].id);
                    }
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={220} style={{ overflowY: "auto" }}>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found&hellip;</Combobox.Empty>
          )}
          {allowNewPlants && search.trim().length > 0 && (
            <Combobox.Option value="$createPlant" key="$createPlant">
              <Group gap="xs">
                <IconPlus size={14} />
                <Text size="sm">Add plant &lsquo;{search.trim()}&rsquo;</Text>
              </Group>
            </Combobox.Option>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
