import { Center, Image } from "@mantine/core";
import { modals } from "@mantine/modals";
import { makeSrcSetProp } from "../lib/util";

type FullscreenPhotoProps = {
  src?: string;
  close?: () => void;
};

// https://sentry.io/answers/generate-random-string-characters-in-javascript/
function createRandomString(length: number) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

export function FullscreenPhoto(props: FullscreenPhotoProps) {
  return (
    <Center h="100%" w="100%" onClick={props.close}>
      <Image
        radius="sm"
        {...makeSrcSetProp(props.src)}
        sizes="100vw"
        fit="contain"
        onClick={props.close}
      />
    </Center>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export default function openFullscreenPhoto(src: string) {
  const modalId = createRandomString(8);
  modals.open({
    modalId: modalId,
    fullScreen: true,
    centered: true,
    withCloseButton: false,
    closeOnClickOutside: true,
    overlayProps: { backgroundOpacity: 0.8, blur: 10 },
    styles: {
      content: {
        backgroundColor: "transparent",
      },
      body: { height: "100%", width: "100%" },
    },
    children: <FullscreenPhoto src={src} close={() => modals.close(modalId)} />,
  });
}
