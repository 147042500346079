import { ActionIcon, Button, Group, Stack } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { ConvexReactClient } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Doc, Id } from "../../convex/_generated/dataModel";
import { utcToday } from "../../convex/shared_util";
import DialogForm from "./DialogForm";
import LocationSelect from "./LocationSelect";

type LocationRow = {
  _id?: Id<"plantLocation"> | null;
  locationId: Id<"locations"> | null;
  startDate: Date | null;
  endDate: Date | null;
};

type PlantLocationFormValues = {
  locations: LocationRow[];
};

type PlantLocationDialogProps = {
  existingLocations: LocationRow[];
  onCancel: () => void;
  onConfirm: (locations: LocationRow[]) => Promise<void>;
};

export default function PlantLocationDialog({
  existingLocations,
  onCancel,
  onConfirm,
}: PlantLocationDialogProps) {
  const form = useForm<PlantLocationFormValues>({
    initialValues: {
      locations: existingLocations,
    },
    validate: {
      locations: {
        locationId: (value) => {
          if (value === null) {
            return "Location is required";
          }
          return null;
        },
        startDate: (value, values, path) => {
          const index = parseInt(path.match(/\d+/)?.[0] ?? "");
          const locations = values.locations;
          const currentLocation = locations[index];
          const prevLocation = index > 0 ? locations[index - 1] : null;

          if (value === null && index !== 0) {
            return "Start date is required";
          }

          if (
            value &&
            currentLocation.endDate &&
            value > currentLocation.endDate
          ) {
            return "Start date must be before end date";
          }

          if (value && prevLocation?.endDate && value < prevLocation.endDate) {
            return "Start date must be after previous location's end date";
          }

          return null;
        },
        endDate: (value, values, path) => {
          const index = parseInt(path.match(/\d+/)?.[0] ?? "");
          const locations = values.locations;
          const currentLocation = locations[index];
          const nextLocation =
            index < locations.length - 1 ? locations[index + 1] : null;

          if (value === null && index !== locations.length - 1) {
            return "End date is required";
          }

          if (
            value &&
            currentLocation.startDate &&
            value <= currentLocation.startDate
          ) {
            return "End date must be after start date";
          }

          if (
            value &&
            nextLocation?.startDate &&
            value > nextLocation.startDate
          ) {
            return "End date must be before next location's start date";
          }

          return null;
        },
      },
    },
  });

  const addRow = () => {
    const locations = form.getValues().locations;
    const lastLocation =
      locations.length > 0 ? locations[locations.length - 1] : null;
    const today = utcToday();
    if (lastLocation?.endDate === null) {
      form.setFieldValue(`locations.${locations.length - 1}.endDate`, today);
    }
    form.insertListItem("locations", {
      locationId: null,
      startDate: lastLocation?.endDate || today,
      endDate: null,
    });
  };

  const removeRow = (index: number) => {
    form.removeListItem("locations", index);
  };

  const getDateConstraints = (index: number) => {
    const locations = form.values.locations;
    const currentLocation = locations[index];
    const prevLocation = index > 0 ? locations[index - 1] : null;
    const nextLocation =
      index < locations.length - 1 ? locations[index + 1] : null;

    return {
      startDateMin: prevLocation?.endDate || undefined,
      startDateMax: currentLocation.endDate || undefined,
      endDateMin: currentLocation.startDate || undefined,
      endDateMax: nextLocation?.startDate || undefined,
    };
  };

  return (
    <DialogForm
      form={form}
      confirmLabel="Save"
      onConfirm={async (values) => {
        await onConfirm(values.locations);
      }}
      cancelLabel="Cancel"
      onCancel={onCancel}
    >
      <Stack>
        {form.values.locations.map((_, index) => {
          const constraints = getDateConstraints(index);

          return (
            <Group
              key={index}
              gap="xs"
              align="flex-start"
              grow
              preventGrowOverflow={false}
              wrap="nowrap"
            >
              <LocationSelect
                size="xs"
                {...form.getInputProps(`locations.${index}.locationId`)}
              />
              <DateInput
                size="xs"
                valueFormat="YYYY-MM-DD"
                placeholder="Start date"
                flex={0}
                style={{ minWidth: 110 }}
                minDate={constraints.startDateMin}
                maxDate={constraints.startDateMax}
                clearable
                {...form.getInputProps(`locations.${index}.startDate`)}
              />
              <DateInput
                size="xs"
                valueFormat="YYYY-MM-DD"
                placeholder="End date"
                flex={0}
                style={{ minWidth: 110 }}
                minDate={constraints.endDateMin}
                maxDate={constraints.endDateMax}
                clearable
                {...form.getInputProps(`locations.${index}.endDate`)}
              />
              <ActionIcon
                color="red"
                variant="subtle"
                onClick={() => removeRow(index)}
              >
                <IconTrash size={18} />
              </ActionIcon>
            </Group>
          );
        })}

        <Button variant="light" onClick={addRow} size="xs">
          Add Location
        </Button>
      </Stack>
    </DialogForm>
  );
}

/* eslint-disable react-refresh/only-export-components */
export function openEditPlantLocationModal(
  convex: ConvexReactClient,
  gardenId: Id<"gardens">,
  plantId: Id<"plants">,
  existingLocations: Doc<"plantLocation">[],
  onConfirm?: (locations: LocationRow[]) => Promise<void>
) {
  modals.open({
    title: "Edit location history",
    modalId: `plant-location-history-${plantId}`,
    children: (
      <PlantLocationDialog
        existingLocations={existingLocations.map((loc) => ({
          _id: loc._id,
          locationId: loc.locationId,
          startDate: loc.startDate ? new Date(loc.startDate) : null,
          endDate: loc.endDate ? new Date(loc.endDate) : null,
        }))}
        onCancel={() => {
          modals.close(`plant-location-history-${plantId}`);
        }}
        onConfirm={async (locations) => {
          await convex.mutation(api.gardens.updatePlantLocations, {
            gardenId: gardenId,
            plantId: plantId,
            locations: locations.map((loc) => ({
              _id: loc._id ?? null,
              locationId: loc.locationId!,
              startDate: loc.startDate?.toISOString() || null,
              endDate: loc.endDate?.toISOString() || null,
            })),
          });
          if (onConfirm) {
            await onConfirm(locations);
          }

          modals.close(`plant-location-history-${plantId}`);
        }}
      />
    ),
  });
}
