import { useEffect, useState } from "react";

import { Button, ButtonProps, Image } from "@mantine/core";

import { PolymorphicComponentProps } from "@mantine/core";

type ImageButtonProps = {
  // source of the image to show
  src?: string;

  // sets the aspect ratio when no image is provided
  defaultAspectRatio?: number;

  // sets the aspect ratio at all times
  aspectRatio?: number;
} & PolymorphicComponentProps<"button", ButtonProps>;

export default function ImageButton({
  src,
  defaultAspectRatio = 3,
  aspectRatio: _aspectRatio,
  children,
  leftSection,
  rightSection,
  ...buttonProps
}: ImageButtonProps) {
  const [aspectRatio, setAspectRatio] = useState(
    _aspectRatio ? _aspectRatio : defaultAspectRatio
  );
  useEffect(() => {
    if (!src) {
      setAspectRatio(_aspectRatio || defaultAspectRatio);
    }
  }, [src, _aspectRatio, defaultAspectRatio]);

  return (
    <Button
      variant="outline"
      leftSection={src ? undefined : leftSection}
      rightSection={src ? undefined : rightSection}
      {...buttonProps}
      styles={{
        root: {
          height: "auto",
          aspectRatio: aspectRatio,
          border:
            "calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-4)",
        },
        inner: {
          position: "absolute",
          inset: 0,
        },
      }}
    >
      {src ? (
        <Image
          src={src}
          fit="cover"
          style={{ aspectRatio: aspectRatio }}
          onLoad={(e) => {
            const img = e.target as HTMLImageElement;
            if (!_aspectRatio)
              setAspectRatio(img.naturalWidth / img.naturalHeight);
          }}
        />
      ) : (
        children
      )}
    </Button>
  );
}
