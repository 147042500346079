import { Carousel } from "@mantine/carousel";
import { Group, Image, Stack, Text, Timeline, Title } from "@mantine/core";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { useRef } from "react";
import { HydratedEntry } from "../../convex/util";
import { formatDate, makeSrcSetProp } from "../lib/util";
import EntryDetail from "./EntryDetail";
import openLightbox from "./Lightbox";
import { usePile } from "./NavPile";
import SubjectPills from "./SubjectPills";

export type EntryTimelineProps = {
  entries: HydratedEntry[];
  onItemClick?: (doc: HydratedEntry) => void;
};

export default function EntryTimeline({ entries }: EntryTimelineProps) {
  const [push] = usePile();

  const wheelGestures = useRef(WheelGesturesPlugin());

  const allImages = new Map(
    entries
      .map((entry) => entry.photos)
      .flat()
      .map((p) => [p.storageId, p.url])
  );
  const allImagesIndexes = new Map(
    entries
      .map((entry) => entry.photos)
      .flat()
      .map((p, idx) => [p.storageId, idx])
  );

  return (
    <Timeline
      active={entries.length - 1}
      color="gray.6"
      lineWidth={3}
      bulletSize={14}
      styles={{
        item: { marginTop: 12 },
        itemBullet: { marginTop: 6 },
      }}
    >
      {entries.map((entry) => {
        return (
          <Timeline.Item
            key={entry._id}
            title={
              <Stack gap="xs" mb="lg">
                <Group gap="xs">
                  <Title
                    order={4}
                    onClick={() => push(<EntryDetail entry={entry} />)}
                    style={{ cursor: "pointer" }}
                  >
                    {formatDate(entry.date)}
                  </Title>
                  {entry.text && <Text size="sm">{entry.text}</Text>}
                </Group>

                {entry.photos.length === 1 && (
                  <Image
                    {...makeSrcSetProp(entry.photos[0].url)}
                    sizes="400px"
                    style={{
                      maxWidth: "400px",
                      width: "100%",
                      aspectRatio: 1,
                      objectFit: "cover",
                      boxShadow: "var(--mantine-shadow-xs)",
                      borderRadius: "var(--mantine-radius-md)",
                      cursor: "pointer",
                    }}
                    loading="lazy"
                    onClick={() =>
                      openLightbox(
                        Array.from(allImages.values()),
                        allImagesIndexes.get(
                          entry.photos[0].storageId
                        ) as number
                      )
                    }
                  />
                )}

                {entry.photos.length > 1 && (
                  <Carousel
                    align="start"
                    slideSize="min(67%, 268px)"
                    slideGap={6}
                    withControls={false}
                    skipSnaps={true}
                    plugins={[wheelGestures.current]}
                    styles={{ viewport: { overflow: "visible" } }}
                  >
                    {entry.photos.map((p) => (
                      <Carousel.Slide key={p.storageId}>
                        <Image
                          {...makeSrcSetProp(p.url)}
                          sizes="200px"
                          style={{
                            aspectRatio: 1,
                            objectFit: "cover",
                            boxShadow: "var(--mantine-shadow-xs)",
                            borderRadius: "var(--mantine-radius-md)",
                            cursor: "pointer",
                          }}
                          loading="lazy"
                          onClick={() =>
                            openLightbox(
                              Array.from(allImages.values()),
                              allImagesIndexes.get(p.storageId) as number
                            )
                          }
                        />
                      </Carousel.Slide>
                    ))}
                  </Carousel>
                )}

                {entry.subjects.length > 0 && (
                  <Group gap="xs">
                    <SubjectPills
                      subjects={entry.subjects}
                      clickable
                      size="sm"
                    />
                  </Group>
                )}
              </Stack>
            }
          />
        );
      })}
    </Timeline>
  );
}
