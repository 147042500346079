import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Stack } from "@mantine/core";

export type SortDirection = "ascending" | "descending";

import SortableButton from "./SortableButton";

type SortPickerProps<T extends string> = {
  fields: T[];
  setFields: (s: T[] | ((t: T[]) => T[])) => void;
  directions: { [key in T]: SortDirection };
  setDirections: (d: { [key in T]: SortDirection }) => void;
  labels: { [key in T]: string };
};

export default function SortPicker<T extends string>({
  fields,
  setFields,
  directions,
  setDirections,
  labels,
}: SortPickerProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 2 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active == null || over == null) {
      // something went terribly wronga
      return;
    }
    if (active.id !== over.id) {
      setFields((fields: T[]) => {
        const oldIndex = fields.indexOf(active.id as T);
        const newIndex = fields.indexOf(over.id as T);
        return arrayMove(fields, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={fields} strategy={verticalListSortingStrategy}>
        <Stack gap="xs">
          {fields.map((id) => (
            <SortableButton
              key={id}
              id={id}
              sortDirection={directions[id]}
              toggleSortDirection={() => {
                const newDirections = { ...directions };
                newDirections[id] =
                  newDirections[id] == "ascending" ? "descending" : "ascending";
                setDirections(newDirections);
              }}
            >
              {labels[id]}
            </SortableButton>
          ))}
        </Stack>
      </SortableContext>
    </DndContext>
  );
}
