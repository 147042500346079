import React from "react";
import ReactDOM from "react-dom/client";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9f7b04b6ae6b273865724e0b2636885d@o4508989841408001.ingest.us.sentry.io/4508989843832833",
});

import { ConvexAuthProvider } from "@convex-dev/auth/react";
import {
  Authenticated,
  AuthLoading,
  ConvexReactClient,
  Unauthenticated,
} from "convex/react";

import { Loader, MantineProvider, rem } from "@mantine/core";

import "@mantine/core/styles.css";

import "@mantine/carousel/styles.css";
import "@mantine/dates/styles.css";

import { Center } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";

import AuthenticatedApp from "./components/AuthenticatedApp.tsx";
import Landing from "./components/Landing.tsx";
import "./index.css";

const convex = new ConvexReactClient(import.meta.env.VITE_CONVEX_URL as string);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ConvexAuthProvider client={convex}>
      <MantineProvider
        theme={{
          headings: {
            sizes: {
              h1: { fontSize: rem(28) },
            },
          },
        }}
      >
        <DatesProvider settings={{ timezone: "UTC" }}>
          <Unauthenticated>
            <Landing />
          </Unauthenticated>

          <AuthLoading>
            <Center pos="absolute" inset={0}>
              <Loader type="bars" />
            </Center>
          </AuthLoading>

          <Authenticated>
            <AuthenticatedApp />
          </Authenticated>
        </DatesProvider>
      </MantineProvider>
    </ConvexAuthProvider>
  </React.StrictMode>
);
