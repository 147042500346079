import { TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { Id } from "../../convex/_generated/dataModel";

import { CoverPhoto, HydratedLocation, StoredFile } from "../../convex/util";

import { modals } from "@mantine/modals";
import { ConvexReactClient } from "convex/react";
import { api } from "../../convex/_generated/api";
import { getCoverPhotoEntryDate } from "../lib/photo";
import CoverPhotoInput, { CoverPhotoInputHandle } from "./CoverPhotoInput";
import DialogForm from "./DialogForm";
import LocationSelect from "./LocationSelect";

type LocationFormValues = {
  name: string;
  coverPhoto: CoverPhoto;
  parentLocationId: Id<"locations"> | null;
};

export type LocationDialogProps = {
  photoInput?: React.ForwardedRef<CoverPhotoInputHandle>;
  confirmLabel: string;
  onConfirm?: (values: LocationFormValues) => Promise<void>;
  cancelLabel?: string;
  onCancel?: () => void;
  destroyLabel?: string;
  onDestroy?: () => Promise<void>;
  destroyConfirm?: {
    title: string;
    text: string;
    confirm: string;
    cancel: string;
  };
  currentLocationId?: Id<"locations">;
  entryPhotos?: StoredFile[] | Promise<StoredFile[]>;
  initialValues?: LocationFormValues;
};

export default function LocationDialog({
  photoInput,
  confirmLabel,
  onConfirm,
  cancelLabel,
  onCancel,
  destroyLabel,
  onDestroy,
  destroyConfirm,
  currentLocationId,
  entryPhotos = [],
  initialValues = {
    name: "",
    coverPhoto: null,
    parentLocationId: null,
  },
}: LocationDialogProps) {
  const form = useForm<LocationFormValues>({
    mode: "uncontrolled",
    initialValues: initialValues,
    validate: {
      name: (value) =>
        value.length == 0 ? "A location name is required" : null,
      coverPhoto: (value) =>
        value != null && value.file.storageId == null
          ? "Photo still uploading"
          : null,
    },
  });

  return (
    <DialogForm
      form={form}
      confirmLabel={confirmLabel}
      onConfirm={onConfirm}
      cancelLabel={cancelLabel}
      onCancel={onCancel}
      destroyLabel={destroyLabel}
      onDestroy={onDestroy}
      destroyConfirm={destroyConfirm}
    >
      <CoverPhotoInput
        ref={photoInput}
        entryPhotos={entryPhotos}
        {...form.getInputProps("coverPhoto")}
        key={form.key("coverPhoto")}
      />
      <TextInput
        // data-autofocus
        placeholder="Location nam&#8203;e" // stupid hack to prevent Safari autofill
        {...form.getInputProps("name")}
        key={form.key("name")}
      />
      <LocationSelect
        label="Sublocation of:"
        clearable
        {...form.getInputProps("parentLocationId")}
        key={form.key("parentLocationId")}
        disabledLocations={currentLocationId ? [currentLocationId] : []}
      />
    </DialogForm>
  );
}

export function openAddLocationModal(
  convex: ConvexReactClient,
  gardenId: Id<"gardens">
) {
  modals.open({
    title: "Add location",
    children: (
      <LocationDialog
        confirmLabel="Add"
        onConfirm={async ({ coverPhoto, ...doc }) => {
          await convex.mutation(api.gardens.createLocation, {
            gardenId: gardenId,
            coverPhotoId: coverPhoto?.file.storageId || null,
            ...doc,
            coverPhotoEntryDate: await getCoverPhotoEntryDate(coverPhoto),
          });
          // TODO: scroll to locationId
        }}
        cancelLabel="Cancel"
      />
    ),
  });
}

export function openEditLocationModal(
  convex: ConvexReactClient,
  doc: HydratedLocation
) {
  let entryQuery = convex.query(api.gardens.listLocationEntries, {
    gardenId: doc.gardenId,
    locationId: doc._id,
  });
  // workaround for Convex bug
  if (!(entryQuery instanceof Promise)) {
    entryQuery = Promise.resolve(entryQuery);
  }
  const entryPhotos = entryQuery.then((entries) =>
    entries.map((doc) => doc.photos).flat()
  );
  modals.open({
    title: "Edit location",
    children: (
      <LocationDialog
        confirmLabel="Save"
        entryPhotos={entryPhotos}
        onConfirm={async (values) => {
          const { coverPhoto, ...newDoc } = values;
          await convex.mutation(api.gardens.updateLocation, {
            id: doc._id,
            gardenId: doc.gardenId,
            coverPhotoId: coverPhoto?.file.storageId || null,
            coverPhotoEntryDate: await getCoverPhotoEntryDate(coverPhoto),
            ...newDoc,
          });
        }}
        cancelLabel="Cancel"
        destroyLabel="Delete"
        onDestroy={async () => {
          await convex.mutation(api.gardens.deleteLocation, { id: doc._id });
        }}
        destroyConfirm={{
          title: `Delete location \u2018${doc.name}\u2019`,
          text: "Are you sure?",
          confirm: "Delete",
          cancel: "Cancel",
        }}
        initialValues={{
          name: doc.name,
          parentLocationId: doc.parentLocationId,
          coverPhoto: doc.coverPhoto
            ? { file: doc.coverPhoto, source: "entry" }
            : null,
        }}
        currentLocationId={doc._id}
      />
    ),
  });
}
