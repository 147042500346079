import { createContext, useContext } from "react";

import type { Doc } from "../../convex/_generated/dataModel";

export const PreferencesContext = createContext<
  Doc<"preferences"> | null | undefined
>(undefined);

export default PreferencesContext;

export const isDebug = () => {
  const prefs = useContext(PreferencesContext);
  const params = new URLSearchParams(window.location.search);
  const debugParam = params.get("debug");
  if (debugParam === "0") return false;
  return prefs?.debug ?? false;
};
