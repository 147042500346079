import { Box, Center, useMantineTheme } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";

export interface FastScrollThumbProps {
  /**
   * The ScrollArea component or element to control
   */
  scrollAreaRef: React.RefObject<HTMLElement>;
  /**
   * Type of scrollbar
   * - auto: Scrollbar is shown only when the content is overflowing
   * - always: Scrollbar is always shown
   * - scroll: Scrollbar is shown only when the content is being scrolled
   * - hover: Scrollbar is shown only when the mouse is over the scroll area
   * @default 'auto'
   */
  type?: "auto" | "always" | "scroll" | "hover";
  /**
   * Size of the thumb in pixels
   * @default 24
   */
  size?: number;
  /**
   * Expand the drag area around the thumb by this amount in pixels
   * @default 24
   */
  expandedDragArea?: number;
  /**
   * Color of the thumb
   * @default 'var(--mantine-color-dark-6)'
   */
  color?: string;
  /**
   * Opacity of the thumb when inactive
   * @default 0.6
   */
  inactiveOpacity?: number;
  /**
   * Opacity of the thumb when active (being dragged)
   * @default 0.9
   */
  activeOpacity?: number;
  /**
   * Right offset in pixels
   * @default 4
   */
  right?: number;
  /**
   * Top offset in pixels
   * @default 4
   */
  top?: number;
  /**
   * Bottom offset in pixels
   * @default 4
   */
  bottom?: number;
  /**
   * Z-index of the thumb
   * @default 100
   */
  zIndex?: number;
  /**
   * Optional icon to display inside the thumb
   * @default undefined
   */
  icon?: React.ReactNode;
  /**
   * Color of the icon
   * @default 'white'
   */
  iconColor?: string;
  /**
   * Size of the icon relative to the thumb size
   * @default 0.6
   */
  iconSizeRatio?: number;
}

export default function FastScrollThumb({
  scrollAreaRef,
  type = "auto",
  size = 24,
  expandedDragArea = 24,
  color,
  inactiveOpacity = 0.6,
  activeOpacity = 0.9,
  right = 4,
  top = 4,
  bottom = 4,
  zIndex = 100,
  icon,
  iconColor = "white",
  iconSizeRatio = 0.6,
}: FastScrollThumbProps) {
  const theme = useMantineTheme();
  const thumbColor = color || theme.colors.dark[6];

  const [isDragging, setIsDragging] = useState(false);
  const [thumbPosition, setThumbPosition] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isActuallyVisible, setIsActuallyVisible] = useState(true);
  const visibilityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { ref: containerRef, height: containerHeight } = useElementSize();

  // Transition duration in ms - keep in sync with the CSS transition time
  const transitionDuration = 300;

  // Determine if the thumb should be visible based on the type
  const isVisible = () => {
    switch (type) {
      case "always":
        return true;
      case "hover":
        return isHovered;
      case "scroll":
        return isScrolling || isDragging;
      case "auto":
      default:
        return true; // In 'auto' mode, visibility is controlled by the parent ScrollArea
    }
  };

  // Update actual visibility with delay when needed
  useEffect(() => {
    const shouldBeVisible = isVisible();

    // Clear any existing timeout
    if (visibilityTimeoutRef.current) {
      clearTimeout(visibilityTimeoutRef.current);
      visibilityTimeoutRef.current = null;
    }

    if (shouldBeVisible) {
      // When becoming visible, update immediately
      setIsActuallyVisible(true);
    } else {
      // When hiding, delay the visibility change until after the opacity transition
      visibilityTimeoutRef.current = setTimeout(() => {
        setIsActuallyVisible(false);
      }, transitionDuration);
    }

    return () => {
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }
    };
  }, [isHovered, isScrolling, isDragging, type]);

  // Update thumb position based on scroll position
  useEffect(() => {
    if (!scrollAreaRef.current || isDragging) return;

    const scrollElement = scrollAreaRef.current;

    const updateThumbPosition = () => {
      if (!scrollElement || !containerRef.current) return;

      const scrollPercentage =
        scrollElement.scrollTop /
        (scrollElement.scrollHeight - scrollElement.clientHeight);

      // Calculate the available space for the thumb to move
      const availableSpace = containerHeight - size;

      // Set the thumb position
      setThumbPosition(scrollPercentage * availableSpace);
    };

    // Handle scroll events and update scrolling state
    const handleScroll = () => {
      updateThumbPosition();
      setIsScrolling(true);
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => setIsScrolling(false), 1000);
    };

    let scrollTimeout: NodeJS.Timeout;

    // Initial position
    updateThumbPosition();

    // Listen for scroll events
    scrollElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, [scrollAreaRef, containerHeight, size, isDragging]);

  // Handle hover events
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  // Handle drag events
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);

    const startY = e.clientY;
    const startThumbPosition = thumbPosition;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!containerRef.current || !scrollAreaRef.current) return;

      const deltaY = moveEvent.clientY - startY;
      const availableSpace = containerHeight - size;

      // Calculate new position with constraints
      const newPosition = Math.max(
        0,
        Math.min(availableSpace, startThumbPosition + deltaY)
      );
      setThumbPosition(newPosition);

      // Calculate scroll percentage and apply to scroll area
      const scrollPercentage = newPosition / availableSpace;
      const scrollElement = scrollAreaRef.current;
      const newScrollTop =
        scrollPercentage *
        (scrollElement.scrollHeight - scrollElement.clientHeight);

      scrollElement.scrollTop = newScrollTop;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  // Handle touch events
  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault();
    setIsDragging(true);

    const touch = e.touches[0];
    const startY = touch.clientY;
    const startThumbPosition = thumbPosition;

    const handleTouchMove = (moveEvent: TouchEvent) => {
      if (!containerRef.current || !scrollAreaRef.current) return;

      const touch = moveEvent.touches[0];
      const deltaY = touch.clientY - startY;
      const availableSpace = containerHeight - size;

      // Calculate new position with constraints
      const newPosition = Math.max(
        0,
        Math.min(availableSpace, startThumbPosition + deltaY)
      );
      setThumbPosition(newPosition);

      // Calculate scroll percentage and apply to scroll area
      const scrollPercentage = newPosition / availableSpace;
      const scrollElement = scrollAreaRef.current;
      const newScrollTop =
        scrollPercentage *
        (scrollElement.scrollHeight - scrollElement.clientHeight);

      scrollElement.scrollTop = newScrollTop;
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  };

  return (
    <Box
      ref={containerRef}
      pos="absolute"
      top={top}
      bottom={bottom}
      right={right}
      h={bottom > 0 ? `calc(100% - ${top}px - ${bottom}px)` : undefined}
      w={size}
      style={{
        zIndex,
        overflow: "visible",
        // backgroundColor: "rgba(0, 255, 0, 0.2)",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Drag handle */}
      <Box
        pos="absolute"
        m={-expandedDragArea}
        top={thumbPosition}
        left={0}
        w={size + 2 * expandedDragArea}
        h={size + 2 * expandedDragArea}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        style={{
          cursor: "grab",
          pointerEvents: isActuallyVisible ? "auto" : "none", // Disable events when hidden
          // Debug visualization (uncomment to see the drag area)
          // backgroundColor: "rgba(255, 0, 0, 0.2)",
        }}
      >
        {/* Visible thumb - now inside the drag handle */}
        <Box
          pos="absolute"
          top={expandedDragArea}
          left={expandedDragArea}
          w={size}
          h={size}
          style={{
            borderRadius: "50%",
            backgroundColor: thumbColor,
            opacity: isVisible()
              ? isDragging
                ? activeOpacity
                : inactiveOpacity
              : 0,
            transition: isDragging
              ? "none"
              : `opacity ${transitionDuration}ms ease`,
            visibility: isActuallyVisible ? "visible" : "hidden", // Delayed visibility change
          }}
        >
          {icon && (
            <Center h="100%">
              <Box
                style={{
                  color: iconColor,
                  transform: `scale(${iconSizeRatio})`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </Box>
            </Center>
          )}
        </Box>
      </Box>
    </Box>
  );
}
