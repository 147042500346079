import { useState } from "react";

import { useAuthActions } from "@convex-dev/auth/react";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";

import {
  Button,
  Fieldset,
  FocusTrap,
  LoadingOverlay,
  Modal,
  Stack,
  Switch,
  Text,
} from "@mantine/core";

type AccountModalProps = {
  opened: boolean;
  onClose: () => void;
};

function AccountModal({ opened, onClose }: AccountModalProps) {
  const { signOut } = useAuthActions();
  const user = useQuery(api.users.currentUser, {});
  const preferences = useQuery(api.users.getPreferences, user ? {} : "skip");
  const setPreferences = useMutation(api.users.setPreferences);

  const [autoCapture, setAutoPhotoCapture] = useState<boolean>(
    preferences?.autoCapture || true
  );

  return (
    <Modal opened={opened} onClose={onClose} title="Account">
      <FocusTrap.InitialFocus />

      <Stack gap="lg">
        <Stack gap={0}>
          <Text fw={700}>{user?.name}</Text>
          <Text size="sm">{user?.email}</Text>
        </Stack>
        <form>
          <LoadingOverlay
            visible={!preferences}
            loaderProps={{ type: "dots" }}
          />
          <Fieldset variant="unstyled" disabled={!preferences}>
            <Switch
              label="Open photo capture when creating new items (mobile only)"
              checked={autoCapture}
              onChange={(event) => {
                const newState = event.currentTarget.checked;
                setAutoPhotoCapture(newState);
                setPreferences({
                  autoCapture: newState,
                });
              }}
            />
          </Fieldset>
        </form>
        {preferences?.debug && (
          <Button
            variant="light"
            onClick={() => {
              throw new Error("test");
            }}
          >
            Test error reporting
          </Button>
        )}
        <Button variant="light" onClick={signOut}>
          Sign out
        </Button>
      </Stack>
    </Modal>
  );
}

export default AccountModal;
