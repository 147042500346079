import { IconPencil } from "@tabler/icons-react";
import { HydratedPlant } from "../../convex/util";
import { LayoutType } from "./LayoutChooser";
import PlantItem from "./PlantItem";

type PlantListProps = {
  layout: LayoutType;
  plants: HydratedPlant[];
  onItemClick?: (doc: HydratedPlant) => void;
  onEditClick?: (doc: HydratedPlant) => void;
};

export default function PlantList({
  layout,
  plants,
  onItemClick,
  onEditClick,
}: PlantListProps) {
  return (
    <>
      {plants.map((doc) => (
        <PlantItem
          key={doc._id}
          variant={layout}
          name={doc.name || ""}
          locationName={doc.location?.name}
          image={doc.coverPhoto?.url}
          archived={doc.archived}
          onClick={() => onItemClick && onItemClick(doc)}
          actionIcons={[
            {
              icon: IconPencil,
              onClick: () => onEditClick && onEditClick(doc),
            },
          ]}
        />
      ))}
    </>
  );
}
