import {
  Center,
  Image,
  SimpleGrid,
  SimpleGridProps,
  UnstyledButton,
} from "@mantine/core";
import { IconCameraPlus, IconPhotoPlus } from "@tabler/icons-react";
import { StoredFile } from "../../convex/util";
import { makeSrcSetProp } from "../lib/util";

export type PhotoPickerProps = {
  photos: StoredFile[];
  withAddButton?: boolean;
  addButtonPosition?: "beginning" | "end";
  addButtonIcon?: "camera" | "photo";
  onPhotoClick?: (value: StoredFile) => void;
  onAddClick?: () => void;
} & SimpleGridProps;

export default function PhotoPicker({
  photos,
  withAddButton = true,
  addButtonPosition = "end",
  addButtonIcon = "photo",
  onPhotoClick,
  onAddClick,
  spacing = 4,
  ...simpleGridProps
}: PhotoPickerProps) {
  const AddIcon = addButtonIcon == "photo" ? IconPhotoPlus : IconCameraPlus;
  const addButton = withAddButton ? (
    <UnstyledButton bg="gray.5" onClick={onAddClick} style={{ aspectRatio: 1 }}>
      <Center>
        <AddIcon size={36} stroke={1.5} color="var(--mantine-color-white)" />
      </Center>
    </UnstyledButton>
  ) : undefined;

  const imageWidth = Math.floor(
    100 /
      (typeof simpleGridProps.cols === "number" ? simpleGridProps.cols || 1 : 1)
  );
  return (
    <SimpleGrid spacing={spacing} {...simpleGridProps}>
      {addButtonPosition == "beginning" && addButton}
      {photos.map((p) => {
        return (
          <UnstyledButton
            key={p.storageId}
            onClick={() => onPhotoClick && onPhotoClick(p)}
          >
            <Image
              {...makeSrcSetProp(p.url)}
              sizes={`${imageWidth}vw`}
              fit="cover"
              bg="gray.5"
              style={{ aspectRatio: 1 }}
            />
          </UnstyledButton>
        );
      })}
      {addButtonPosition == "end" && addButton}
    </SimpleGrid>
  );
}
