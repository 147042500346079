import { MantineSize, Pill, Text } from "@mantine/core";
import { IconMapPin, IconSeeding } from "@tabler/icons-react";
import { HydratedEntry } from "../../convex/util";
import DebugTooltip from "./DebugTooltip";
import LocationDetail from "./LocationDetail";
import { usePile } from "./NavPile";
import PlantDetail from "./PlantDetail";

export type SubjectPillsProps = {
  subjects: HydratedEntry["subjects"];
  includePlantLocation?: boolean;
  clickable?: boolean;
  size?: MantineSize;
};

export default function SubjectPills({
  subjects,
  includePlantLocation = false,
  clickable = false,
  size = "md",
}: SubjectPillsProps) {
  const [push] = usePile(clickable);
  const pills = subjects.map((s) => {
    const onClick = clickable
      ? () => {
          switch (s.type) {
            case "location":
              push(<LocationDetail location={s.doc} />, s.doc.name);
              break;
            case "plant":
              push(<PlantDetail plant={s.doc} />, s.doc.name);
              break;
          }
        }
      : undefined;
    return (
      <Pill
        key={s.doc._id}
        size={size}
        styles={{
          root: clickable ? { cursor: "pointer" } : {},
          label: { gap: 2, display: "flex", alignItems: "center" },
        }}
        bg="gray.3"
        onClick={onClick}
      >
        {s.type == "plant" && <IconSeeding size={14} />}
        {s.type == "location" && <IconMapPin size={14} />}
        <DebugTooltip label={s.doc._id}>
          <Text size="xs">
            {s.doc.name}
            {s.type == "plant" &&
              includePlantLocation &&
              s.doc.location &&
              ` (${s.doc.location.name})`}
          </Text>
        </DebugTooltip>
      </Pill>
    );
  });
  return <>{pills}</>;
}
