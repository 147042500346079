import { useContext } from "react";

import { useConvex, useConvexAuth, useQuery } from "convex/react";

import { api } from "../../convex/_generated/api";
import type { Id } from "../../convex/_generated/dataModel";
import { HydratedGarden } from "../../convex/util";

import {
  ActionIcon,
  Avatar,
  Box,
  Combobox,
  Group,
  Loader,
  Paper,
  Text,
  Title,
  rem,
  useCombobox,
} from "@mantine/core";

import { IconPencil, IconPlus, IconUserCircle } from "@tabler/icons-react";

import PlantingsIcon from "./PlantingsIcon";

import GardenContext from "./GardenContext";
import { openAddGardenModal } from "./GardenDialog";

type TopBarProps = {
  onAccountClick: () => void;
  onGardenEdit: () => void;
  onGardenChange: (garden: HydratedGarden) => void;
};

function TopBar({ onAccountClick, onGardenEdit, onGardenChange }: TopBarProps) {
  const convexAuth = useConvexAuth();
  const convex = useConvex();
  const currentGarden = useContext(GardenContext);
  const gardens = useQuery(
    api.gardens.listGardens,
    convexAuth.isAuthenticated ? {} : "skip"
  );

  const gardensById = gardens?.reduce(
    (acc, doc) => {
      acc[doc._id] = doc;
      return acc;
    },
    {} as {
      [id: Id<"gardens">]: HydratedGarden;
    }
  );
  const combobox = useCombobox();

  if (gardens && gardens.length > 0 && !currentGarden) {
    // finished loading gardens, set current garden after this render pass finishes
    setTimeout(() => {
      onGardenChange(gardens[0]);
    }, 0);
  }

  return (
    <Box
      bg="gray.1"
      px="sm"
      py="xs"
      style={{
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        gap: rem(5),
        boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.3)",
        // backdropFilter: "blur(10px)",
      }}
    >
      <Combobox
        store={combobox}
        width={300}
        position="bottom-start"
        shadow="lg"
        withArrow
        arrowSize={11}
        arrowPosition="center"
        arrowOffset={13}
        radius="md"
        offset={2}
        size="md"
        onOptionSubmit={(val) => {
          if (val === "$newGarden") {
            combobox.closeDropdown();
            openAddGardenModal(convex, async (gardenId) => {
              const gardens = await convex.query(api.gardens.listGardens, {});
              onGardenChange(gardens.filter((g) => g._id == gardenId)[0]);
            });
          } else if (gardensById) {
            onGardenChange(gardensById[val as Id<"gardens">]);
            combobox.closeDropdown();
          }
        }}
      >
        <Combobox.Target targetType="button">
          <ActionIcon
            variant="transparent"
            size={30}
            onClick={() => combobox.toggleDropdown()}
          >
            <PlantingsIcon size={28} />
          </ActionIcon>
        </Combobox.Target>
        <Combobox.Dropdown>
          <Combobox.Options>
            {gardens?.map((doc) => {
              return (
                <Combobox.Option value={doc._id} key={doc._id}>
                  <Group wrap="nowrap">
                    <Group gap="xs" flex={1}>
                      <Avatar
                        variant="filled"
                        src={doc.coverPhoto?.url}
                        radius="sm"
                      >
                        {doc.name.slice(0, 2)}
                      </Avatar>
                      <Text size="xl" c="gray.7">
                        {doc.name}
                      </Text>
                    </Group>
                    {doc._id === currentGarden?._id && (
                      <ActionIcon
                        color="gray.7"
                        variant="subtle"
                        onClick={onGardenEdit}
                      >
                        <IconPencil size={18} />
                      </ActionIcon>
                    )}
                  </Group>
                </Combobox.Option>
              );
            })}
            <Combobox.Option value="$newGarden" key="$newGarden">
              <Group gap="xs">
                <Paper
                  w={36}
                  h={36}
                  bg="blue.6"
                  radius="sm"
                  display="flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <IconPlus color="white" size={28} />
                </Paper>
                <Text size="xl" c="gray.7">
                  Create new garden
                </Text>
              </Group>
            </Combobox.Option>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      <Title order={1} style={{ flex: 1 }}>
        {currentGarden ? currentGarden.name : <Loader type="dots" />}
      </Title>
      <ActionIcon variant="transparent" onClick={onAccountClick}>
        <IconUserCircle />
      </ActionIcon>
    </Box>
  );
}

export default TopBar;
